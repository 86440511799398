import React, { forwardRef } from "react";
import classNames from "lib/classNames";

const Button = forwardRef(
  (
    {
      children,
      theme = "primary",
      as = "button",
      size = "default",
      inverse = false,
      ...props
    },
    ref
  ) => {
    const Tag = as;

    const themes = {
      primary: {
        default:
          "border-primary bg-primary text-white hover:bg-white hover:text-primary",
        inverse:
          "border-primary bg-transparent text-primary hover:bg-primary hover:text-white",
      },
      secondary: {
        default:
          "border-secondary bg-transparent text-secondary hover:bg-secondary hover:text-white",
        inverse:
          "border-secondary bg-secondary text-white hover:bg-transparent hover:text-secondary",
      },
      white: {
        default:
          "border-white bg-transparent text-white hover:bg-white hover:text-black",
      },
      pagination: {
        default: "border-primary bg-primary text-white",
        inverse:
          "border-primary bg-transparent text-primary hover:bg-primary hover:text-white",
      },
    };

    const sizes = {
      default: "pt-3 pb-2 px-8 2xl:pt-5 2xl:pb-4 2xl:px-14",
      small: "py-2 w-max px-8 text-basic",
      banners: "pb-2 pt-3 px-9 leading-none text-3xl",
      pagination: "pt-3 pb-2 lg:pt-4 lg:pb-5 w-max px-4 lg:px-8",
      "pagination-next-back":
        "pt-2 pb-3 lg:pt-4 lg:pb-3 items-center w-max px-4 lg:px-8",
    };

    return (
      <Tag
        ref={ref}
        className={classNames(
          "inline-block border-2 h-max text-xl 2xl:text-3xl font-teko uppercase tracking-wider transition",
          inverse ? themes[theme].inverse : themes[theme].default,
          sizes[size]
        )}
        {...props}
      >
        {children}
      </Tag>
    );
  }
);

Button.displayName = "Button";
export default Button;
