import React from "react";
import useField from "lib/useField";

export default function Checkbox({ register, errors, field }) {
  const { id, name, disabled, required, requiredMessage } = useField(field);
  const hasError = errors[name] || false;

  return (
    <div className="flex flex-col">
      {hasError && (
        <p className="text-xs lg:text-sm text-red-500 mb-2 font-semibold">
          {errors[name].message}
        </p>
      )}
      <div className="flex">
        <input
          id={id}
          name={name}
          type="checkbox"
          {...register(name, {
            required: required && requiredMessage,
            disabled,
          })}
          className="mr-2"
        />
        <label className="text-xs whitespace-nowrap" htmlFor={id}>
          {field["#title"]}
        </label>
      </div>
    </div>
  );
}
