import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "next/image";
import { Autoplay, Navigation } from "swiper";
import Link from "next/link";
import Button from "components/Button";
import BasicBlock from "components/Block/BasicBlock";
import "swiper/css";
import SlidePrevButton from "components/Swiper/SlidePrevButton";
import SlideNextButton from "components/Swiper/SlideNextButton";
import classNames from "lib/classNames";

export default function NodeBannerSlider({
  nodes,
  loop = false,
  globals,
  isHome,
}) {
  const [swiper, setSwiper] = useState();
  const [currentSlide, setCurrentSlide] = useState("01");
  const [slideNb, setSlideNb] = useState(0);

  const slidesNumber = nodes.length < 10 ? `0${nodes.length}` : nodes.length;

  return (
    <div className={classNames(isHome ? "" : "pt-10", "h-screen")}>
      <Swiper
        slidesPerView={1}
        modules={[Autoplay, Navigation]}
        loop={loop}
        speed={800}
        onSlideChange={(index) => {
          setCurrentSlide(
            index.snapIndex + 1 < 10
              ? `0${index.snapIndex + 1}`
              : index.snapIndex + 1
          );
          setSlideNb(index.snapIndex);
        }}
        onSwiper={setSwiper}
        className="h-full"
      >
        <div className="hidden sm:block">
          <div className="absolute bottom-0 left-0 flex z-20">
            <div className="border-r">
              <BasicBlock data={globals.blocks.findUs} className="px-12" />
            </div>
            <BasicBlock
              data={globals.blocks.contact}
              className="pl-12 pr-32"
              phone
            />
          </div>
          <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-full container px-4 z-50">
            <div className="flex items-center gap-4 mb-4">
              <div className="flex items-baseline">
                <span className="text-6xl font-teko text-white">
                  {currentSlide}
                </span>
                <span className="text-5xl font-teko text-white/30 ">
                  &nbsp;/ {slidesNumber}
                </span>
              </div>
              {swiper && nodes.length > 1 && (
                <div className="flex gap-2.5">
                  <SlidePrevButton
                    theme="outline-white"
                    size="medium"
                    disabled={slideNb === 0}
                    swiper={swiper}
                  />
                  <SlideNextButton
                    theme="outline-white"
                    size="medium"
                    disabled={slideNb === nodes.length - 1}
                    swiper={swiper}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="sm:hidden absolute top-[50%] mx-auto w-full flex space-x-2 items-center justify-center z-50">
          {swiper && nodes.length > 1 && (
            <SlidePrevButton
              theme="outline-white"
              size="small"
              disabled={slideNb === 0}
              swiper={swiper}
            />
          )}
          <div className="flex items-baseline pt-2 px-2.5">
            <span className="text-4xl font-teko text-white">
              {currentSlide}
            </span>
            <span className="text-3xl font-teko text-white/30">
              &nbsp;/ {slidesNumber}
            </span>
          </div>
          {swiper && nodes.length > 1 && (
            <SlideNextButton
              theme="outline-white"
              size="small"
              disabled={slideNb === nodes.length - 1}
              swiper={swiper}
            />
          )}
        </div>
        <div className="relative z-50">
          {nodes.map((slide) => {
            return (
              <SwiperSlide
                key={slide.id}
                className="aspect-h-1 aspect-w-2 relative overflow-hidden"
              >
                <div className="hidden sm:block absolute scale-100 bg-black opacity-20 w-full h-full bottom-0 -left-[25rem] -skew-x-[30deg] z-10" />
                <div className="w-full h-full bg-black">
                  <Image
                    src={`${slide.field_image.image_style_uri.hq}`}
                    layout="fill"
                    objectFit="cover"
                    className="opacity-80"
                    alt={slide.field_image.resourceIdObjMeta.alt}
                  />
                </div>
                <div className="bg-black opacity-20 w-full h-full" />
                <div className="hidden sm:block">
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 z-20 container px-4">
                    <div>
                      <p className="w-max text-primary font-bold tracking-wider text-4xl uppercase">
                        {slide.field_subtitle}
                      </p>
                      <h2 className="text-6xl xl:text-7xl 2xl:text-[120px] text-white tracking-wide w-3/4 uppercase whitespace-nowrap">
                        {slide.title ? slide.title : slide.field_title}
                      </h2>
                      {slide.field_link && (
                        <Link href={slide.field_link.url} passHref>
                          <a>
                            <Button theme="primary" size="banners">
                              {slide.field_link.title}
                            </Button>
                          </a>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="sm:hidden absolute top-[58%] z-20 px-4">
                  <div className="flex flex-col justify-center items-center">
                    <p className="w-max text-primary font-bold tracking-wider text-xl uppercase">
                      {slide.field_subtitle}
                    </p>
                    <h2 className="text-5xl text-white tracking-wide uppercase text-center">
                      {slide.title ? slide.title : slide.field_title}
                    </h2>
                    {slide.field_link && (
                      <Link href={slide.field_link.url} passHref>
                        <a className="mt-4">
                          <Button theme="primary" size="banners">
                            {slide.field_link.title}
                          </Button>
                        </a>
                      </Link>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </div>
      </Swiper>
    </div>
  );
}
