import NodeCommitmentTeaser from "components/Commitments/NodeCommitmentTeaser";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useState } from "react";
import SlidePrevButton from "components/Swiper/SlidePrevButton";
import SlideNextButton from "components/Swiper/SlideNextButton";
import Button from "components/Button";
import Link from "next/link";

export default function NodeCommitmentsList({ nodes }) {
  const [swiper, setSwiper] = useState(null);
  const [slideNb, setSlideNb] = useState(0);

  return (
    <div className="container mx-auto px-4 xl:pt-32 pb-10">
      <div className="relative">
        <h1 className="uppercase text-center text-5xl 2xl:text-8xl lg:text-start">
          Nos engagements —
        </h1>
        <div className="hidden xl:flex absolute top-2 right-0 space-x-6 items-center justify-center">
          {swiper && nodes.length > 4 && (
            <>
              <SlidePrevButton
                theme="outline-dark"
                disabled={slideNb === 0}
                swiper={swiper}
              />
              <SlideNextButton
                theme="outline-dark"
                disabled={slideNb === nodes.length - 4}
                swiper={swiper}
              />
            </>
          )}
        </div>
        <div className="hidden lg:flex xl:hidden absolute top-2 right-0 space-x-6 items-center justify-center">
          {swiper && nodes.length > 2 && (
            <>
              <SlidePrevButton
                theme="outline-dark"
                disabled={slideNb === 0}
                swiper={swiper}
              />
              <SlideNextButton
                theme="outline-dark"
                disabled={slideNb === nodes.length - 2}
                swiper={swiper}
              />
            </>
          )}
        </div>
        <Swiper
          spaceBetween={20}
          modules={[Navigation]}
          onSwiper={setSwiper}
          onSlideChange={(index) => setSlideNb(index.snapIndex)}
          className="mt-10 xl:overflow-slider-visible"
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1280: {
              slidesPerView: 4,
            },
          }}
        >
          {nodes.map((node, index) => (
            <SwiperSlide key={node.id}>
              <div className="pb-16 px-4 lg:px-0">
                <NodeCommitmentTeaser commitment={node} index={index + 1} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="lg:hidden absolute bottom-0 w-full flex space-x-6 justify-center z-50">
          {swiper && nodes.length > 1 && (
            <>
              <SlidePrevButton
                theme="outline-dark"
                size="medium"
                disabled={slideNb === 0}
                swiper={swiper}
              />
              <SlideNextButton
                theme="outline-dark"
                size="medium"
                disabled={slideNb === nodes.length - 1}
                swiper={swiper}
              />
            </>
          )}
        </div>
      </div>
      <div className="flex justify-center w-full mt-10 lg:mt-0">
        <Link href="/motors-legend" passHref>
          <Button as="a" theme="primary">
            En savoir plus
          </Button>
        </Link>
      </div>
    </div>
  );
}
