import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Link from "next/link";
import classNames from "lib/classNames";
import Wheel from "components/Icons/Wheel";
import Phone from "components/Icons/Phone";
import Letter from "components/Icons/Letter";
import Branding from "components/Branding";
import { useRouter } from "next/router";

export default function MobileMenu({ menu }) {
  const router = useRouter();

  return (
    <div className="z-50 w-full">
      <Menu>
        <div className="flex -mt-8 space-x-4 items-center justify-between">
          <div className="border-r pt-4">
            <Branding />
          </div>
          <div className="flex items-center pt-4">
            <div className="flex space-x-4 w-full justify-end pr-6">
              <Link href="/a-vendre" passHref>
                <a className="bg-white h-9 w-9 rounded-full p-1.5">
                  <Wheel className="w-full h-full text-primary" />
                </a>
              </Link>
              <a
                href="tel:+33974566432"
                className="bg-white h-9 w-9 rounded-full p-1.5"
              >
                <Phone className="w-full h-full text-primary" />
              </a>
              <a
                href="#contact"
                className="bg-white h-9 w-9 rounded-full p-1.5"
              >
                <Letter className="w-full h-full text-primary" />
              </a>
            </div>
            <Menu.Button
              className="flex justify-end"
              aria-label="Afficher le menu secondaire"
            >
              <MenuIcon className="h-10 w-10 text-white" />
            </Menu.Button>
          </div>
        </div>
        <Transition>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 h-full w-full bg-black bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Menu.Items className="fixed right-0 top-0 h-full w-80 bg-black p-4 shadow-lg focus:outline-none">
              <div className="absolute top-0 right-0 p-4">
                <Menu.Button aria-label="Masquer le menu secondaire">
                  <XIcon className="w-8 fill-white stroke-white" />
                </Menu.Button>
              </div>
              <div className="divide-y divide-white py-1 pt-8">
                {menu.map((item) => {
                  return (
                    <div key={item.id} className="px-1 py-1">
                      <Menu.Button>
                        <a
                          href={item.url}
                          className={classNames(
                            router.asPath === item.url
                              ? "text-primary"
                              : "text-white",
                            "flex w-full px-2 py-2 font-teko text-2xl uppercase hover:text-primary"
                          )}
                        >
                          <span>{item.title}</span>
                        </a>
                      </Menu.Button>
                    </div>
                  );
                })}
              </div>
            </Menu.Items>
          </Transition.Child>
        </Transition>
      </Menu>
    </div>
  );
}
