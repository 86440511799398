import React, { createRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import EmailNewsletter from "components/Newsletter/EmailNewsletter";
import SocialNetworksMenu from "components/Menu/SocialNetworksMenu";
import Checkbox from "components/Form/Fields/Checkbox";

export default function Newsletter({ id, dataFields, socialNetworkMenu }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const [status, setStatus] = useState();
  const [message, setMessage] = useState();
  const formRef = createRef();

  useEffect(() => {
    if (status === "success") {
      formRef.current.reset();
      reset({});
    }
  }, [status]);

  async function onSubmit(data) {
    // Prevent submit spam
    if (status === "submitting") {
      return undefined;
    }

    setStatus("submitting");
    setMessage("");

    const response = await fetch(`/api/forms/submit`, {
      method: "POST",
      body: JSON.stringify({
        webform_id: id,
        ...data,
      }),
    });

    if (response.ok) {
      setStatus("success");
      setMessage(
        "Votre inscription a la newsletter a bien été prise en compte."
      );
    } else {
      setStatus("error");
      setMessage(
        "Une erreur est survenue lors de la soumission du formulaire. <br>Merci de contacter un administrateur ou de réessayer plus tard."
      );
    }

    return undefined;
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <section>
        <div className="mx-auto px-4 bg-newsletter-mobile bg-cover lg:bg-newsletter bg-no-repeat bg-center py-10 lg:py-16">
          <div className="pb-6 2xl:pb-12">
            <SocialNetworksMenu menu={socialNetworkMenu} />
          </div>
          <h1 className="text-5xl 2xl:text-7xl text-center mb-4 uppercase">
            <span className="text-white">Je m&apos;abonne à</span>
            <span className="text-primary">&nbsp;la newsletter</span>
          </h1>
          {message !== "" &&
            (status === "success" ? (
              <p className="text-green-500 text-center">{message}</p>
            ) : (
              <p className="text-primary text-center">{message}</p>
            ))}

          <div className="flex flex-col px-8 items-center lg:flex-row lg:space-y-0 lg:items-start lg:space-x-4 lg:mx-auto lg:justify-center lg:max-w-screen-lg relative">
            <EmailNewsletter
              register={register}
              control={control}
              errors={errors}
              field={dataFields.email}
            />
            <div className="order-3 lg:order-2 mt-10 lg:mt-0">
              <Button type="submit">Je m&apos;inscris</Button>
            </div>
            <div className="order-2 lg:order-3 lg:absolute lg:right-[20px] 2xl:right-[68px] -bottom-8 text-white mt-4 lg:mt-0">
              <Checkbox
                register={register}
                control={control}
                errors={errors}
                field={dataFields.privacy_policy}
              />
            </div>
          </div>
        </div>
      </section>
    </form>
  );
}
