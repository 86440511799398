import React from "react";
import Image from "next/image";
import Link from "next/link";
import { PlusIcon } from "@heroicons/react/outline";
import { useRouter } from "next/router";
import classNames from "lib/classNames";
import formatPrice from "lib/formatPrice";

export default function NodeVehicleTeaser({
  node,
  wide = false,
  fill = false,
}) {
  const router = useRouter();

  const clickHandler = () => {
    router.push(node.path.alias);
  };

  return (
    <article
      className={classNames(
        fill && "h-full",
        "group flex flex-col cursor-pointer"
      )}
      onClick={() => clickHandler()}
    >
      <div className="group-hover:scale-[1.15] 2xl:group-hover:scale-[1.2] transition bg-white relative z-20 group-hover:z-40">
        <div className="group-hover:bg-black group-hover:bg-opacity-40 transition">
          <div
            className="bg-gray-400 overflow-hidden aspect-w-3 aspect-h-2"
            aria-hidden="true"
          >
            <div>
              {node.field_slider[0]?.image_style_uri.medium && (
                <Image
                  src={node.field_slider[0].image_style_uri.medium}
                  width={node.field_slider[0].resourceIdObjMeta.width}
                  height={node.field_slider[0].resourceIdObjMeta.height}
                  layout="fill"
                  objectFit="cover"
                  alt={node.field_slider[0].resourceIdObjMeta.alt}
                />
              )}
              <div className="absolute inset-0 bg-white bg-opacity-50 group-hover:bg-opacity-0 transition" />
            </div>
          </div>
          <Link href={node.path.alias} passHref>
            <a className="hidden absolute top-0 right-10 bg-white group-hover:block text-black py-4 px-4 transition -skew-x-12">
              <PlusIcon className="h-5 w-5 stroke-[4px] skew-x-12" />
              <span className="sr-only">Voir tout</span>
            </a>
          </Link>

          <div className={classNames(fill && "flex-1", "p-4 2xl:p-7")}>
            <div className="flex flex-col">
              <h2 className="text-3xl pb-4 group-hover:text-white">
                {node.title}
              </h2>

              {wide && node.body?.summary && (
                <p className="hidden xl:block mt-6">{node.body.summary}</p>
              )}
              <p className="bg-primary p-2 w-max text-white tracking-widest font-semibold text-sm 2xl:text-lg uppercase">
                {formatPrice(node.field_price)} €
              </p>
              <div className="flex flex-row pt-4 whitespace-nowrap whitespace-nowrap text-sm 2xl:text-lg justify-between group-hover:text-white transition">
                <div>
                  <p className="text-black text-opacity-60 text-xs 2xl:text-sm">
                    Boite de vitesses
                  </p>
                  <p>{node.field_gearbox}</p>
                </div>
                <div>
                  <p className="text-black text-opacity-60 text-xs 2xl:text-sm">
                    Carburant
                  </p>
                  <p>{node.field_fuel.name}</p>
                </div>
                <div>
                  <p className="text-black text-opacity-60 text-xs 2xl:text-sm">
                    Puissance
                  </p>
                  <p>{node.field_horsepower} cv</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export function NodeVehicleTeaserForSale({ node, wide = false, fill = false }) {
  const router = useRouter();

  const clickHandler = () => {
    router.push(node.path.alias);
  };

  const oneMonthBefore = new Date();
  oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);

  return (
    <article
      className={classNames(
        fill && "h-full",
        "group flex flex-col cursor-pointer lg:pb-8 pt-16"
      )}
      onClick={() => clickHandler()}
    >
      <div className="group-hover:scale-110 transition bg-white relative">
        <div
          className="bg-gray-400 overflow-hidden aspect-w-1 aspect-h-1"
          aria-hidden="true"
        >
          <div>
            {node.field_slider[0]?.image_style_uri.medium && (
              <>
                <Image
                  src={node.field_slider[0].image_style_uri.medium}
                  width={node.field_slider[0].resourceIdObjMeta.width}
                  height={node.field_slider[0].resourceIdObjMeta.height}
                  layout="fill"
                  objectFit="cover"
                  alt={node.field_slider[0].resourceIdObjMeta.alt}
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:bg-opacity-0 transition" />
              </>
            )}
            {oneMonthBefore < new Date(node.created) && (
              <div className="absolute bottom-0 right-0 bg-primary px-4 2xl:px-10 2xl:py-1.5 pb-1">
                <span className="text-xs 2xl:text-lg text-white uppercase font-semibold">
                  Nouveauté
                </span>
              </div>
            )}
          </div>
        </div>
        <Link href={node.path.alias} passHref>
          <a className="hidden absolute -top-6 right-10 bg-white group-hover:block text-black py-4 px-4 transition -skew-x-12">
            <PlusIcon className="h-5 w-5 stroke-[4px] skew-x-12" />
            <span className="sr-only">Voir tout</span>
          </a>
        </Link>

        <div className={classNames(fill && "flex-1", "p-4 2xl:p-8")}>
          <div className="flex flex-col">
            <h2 className="text-3xl 2xl:text-5xl pb-4">{node.title}</h2>
            {wide && node.body?.summary && (
              <p className="hidden xl:block mt-6">{node.body.summary}</p>
            )}
            <div className="flex flex-row pb-4 whitespace-nowrap text-sm 2xl:text-xl justify-between">
              <div>
                <p className="text-black text-opacity-60 text-xs 2xl:text-lg">
                  Boite de vitesses
                </p>
                <p>{node.field_gearbox}</p>
              </div>
              <div>
                <p className="text-black text-opacity-60 text-xs 2xl:text-lg">
                  Carburant
                </p>
                <p>{node.field_fuel.name}</p>
              </div>
              <div>
                <p className="text-black text-opacity-60 text-xs 2xl:text-lg">
                  Puissance
                </p>
                <p>{node.field_horsepower} cv</p>
              </div>
            </div>
            <div className="flex text-sm 2xl:text-xl whitespace-nowrap items-center">
              <p className="bg-primary p-2 w-max text-white font-semibold tracking-widest">
                {formatPrice(node.field_price)} €
              </p>
              {node.field_mensual_price && (
                <p className="pl-3 text-primary font-teko">
                  à partir de {formatPrice(node.field_mensual_price)} €/mois
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export function NodeVehicleTeaserSold({ node, wide = false, fill = false }) {
  const router = useRouter();

  const clickHandler = () => {
    router.push(node.path.alias);
  };

  return (
    <article
      className={classNames(
        fill && "h-full",
        "group flex flex-col cursor-pointer"
      )}
      onClick={() => clickHandler()}
    >
      <div className="group-hover:scale-[1.15] 2xl:group-hover:scale-[1.2] transition bg-white relative z-20 group-hover:z-40">
        <div className="group-hover:bg-black group-hover:bg-opacity-40 transition">
          <div
            className="bg-gray-400 overflow-hidden aspect-w-3 aspect-h-2"
            aria-hidden="true"
          >
            <div>
              {node.field_slider[0]?.image_style_uri.medium && (
                <Image
                  src={node.field_slider[0].image_style_uri.medium}
                  width={node.field_slider[0].resourceIdObjMeta.width}
                  height={node.field_slider[0].resourceIdObjMeta.height}
                  layout="fill"
                  objectFit="cover"
                  alt={node.field_slider[0].resourceIdObjMeta.alt}
                />
              )}
              <div className="absolute inset-0 bg-white bg-opacity-50 group-hover:bg-opacity-0 transition" />
            </div>
          </div>
          <Link href={node.path.alias} passHref>
            <a className="hidden absolute top-0 right-10 bg-white group-hover:block text-black py-4 px-4 transition -skew-x-12">
              <PlusIcon className="h-5 w-5 stroke-[4px] skew-x-12" />
              <span className="sr-only">Voir tout</span>
            </a>
          </Link>

          <div className={classNames(fill && "flex-1", "p-4 2xl:p-7")}>
            <div className="flex flex-col">
              <h2 className="text-3xl pb-4 group-hover:text-white">
                {node.title}
              </h2>

              {wide && node.body?.summary && (
                <p className="hidden xl:block mt-6">{node.body.summary}</p>
              )}
              <p className="bg-primary p-2 w-max text-white tracking-widest font-semibold text-sm 2xl:text-lg uppercase">
                vendu
              </p>
              <div className="flex flex-row pt-4 whitespace-nowrap whitespace-nowrap text-sm 2xl:text-lg justify-between group-hover:text-white transition">
                <div>
                  <p className="text-black text-opacity-60 text-xs 2xl:text-sm">
                    Boite de vitesses
                  </p>
                  <p>{node.field_gearbox}</p>
                </div>
                <div>
                  <p className="text-black text-opacity-60 text-xs 2xl:text-sm">
                    Carburant
                  </p>
                  <p>{node.field_fuel.name}</p>
                </div>
                <div>
                  <p className="text-black text-opacity-60 text-xs 2xl:text-sm">
                    Puissance
                  </p>
                  <p>{node.field_horsepower} cv</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export function NodeVehicleTeaserSkeleton({}) {
  return (
    <div className="flex flex-col relative z-30 w-full h-full animate-pulse">
      <span className="bg-gray-200 w-full h-48 2xl:h-56" />
      <div className="bg-white w-full flex flex-col py-4 px-4 2xl:px-8 2xl:py-6">
        <span className="bg-black w-32 h-6 2xl:h-8 mb-6 2xl:mb-8" />
        <span className="bg-primary w-24 h-8 2xl:h-10" />
        <div className="flex flex-row mt-6 justify-between">
          <span className="bg-black opacity-20 w-20 2xl:w-24 h-3" />
          <span className="bg-black opacity-20 w-12 2xl:w-20 h-3" />
          <span className="bg-black opacity-20 w-12 2xl:w-20 h-3" />
        </div>
        <div className="flex flex-row pt-4 justify-between">
          <span className="bg-black w-20 h-4 2xl:w-24 2xl:h-6" />
          <span className="bg-black w-12 h-4 2xl:w-20 2xl:h-6" />
          <span className="bg-black w-12 h-4 2xl:w-20 2xl:h-6" />
        </div>
      </div>
    </div>
  );
}
