import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { Controller } from "react-hook-form";
import classNames from "lib/classNames";
import useField from "lib/useField";

export default function Select({ control, errors, field }) {
  const { id, name, disabled, required, requiredMessage, options } =
    useField(field);
  const hasError = errors[name] || false;

  if (options === null) {
    return null;
  }

  return (
    <>
      <label htmlFor={id} className="sr-only">
        {field["#title"]}
      </label>

      {hasError && (
        <p className="text-sm text-red-500 mb-2 font-semibold">
          {errors[name].message}
        </p>
      )}

      <Controller
        id={id}
        name={name}
        control={control}
        rules={{
          required: required && requiredMessage,
          disabled,
        }}
        render={({ field: { value, onChange } }) => (
          <Listbox value={value} onChange={onChange}>
            {({ open }) => (
              <div className="relative w-full h-full">
                <Listbox.Button
                  className={classNames(
                    hasError ? "border-red-500" : "border-black/30",
                    "text-left w-full border h-full px-4 bg-white text-black/40 text-xs lg:text-sm py-4"
                  )}
                >
                  <span
                    className={classNames(
                      value && value !== "" && "text-black",
                      "block truncate"
                    )}
                  >
                    {value &&
                    value !== "" &&
                    options.find((val) => val.value === value)
                      ? options.find((val) => val.value === value).key
                      : field["#title"]}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                    <SelectorIcon
                      className="h-5 w-5 text-black"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    static
                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white text-black/40 shadow-lg focus:outline-none"
                  >
                    {options.map((option) => (
                      <Listbox.Option
                        key={option.id}
                        className={({ active }) =>
                          classNames(
                            active && "bg-primary/40",
                            "relative cursor-default select-none p-4"
                          )
                        }
                        value={option.value}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "pl-6 font-medium" : "font-normal",
                                "block truncate text-xs lg:text-sm"
                              )}
                            >
                              {option.key}
                            </span>
                            {selected ? (
                              <span
                                className="absolute inset-y-0 left-0 flex items-center pl-2.5"
                                aria-hidden="true"
                              >
                                <CheckIcon className="h-5 w-5" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            )}
          </Listbox>
        )}
      />
    </>
  );
}
