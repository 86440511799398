import React from "react";

export default function Motorslegend({ className }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2836 1024"
      className={className}
    >
      <path
        fill="#a8a8a8"
        opacity="0.263"
        d="M0 1011.322h160.976l730.427-692.195v-317.921z"
      />
      <path
        fill="#d8d8d8"
        opacity="0.263"
        d="M1944.851 0v317.927l730.43 692.195h160.974z"
      />
      <path
        fill="#a8a8a8"
        opacity="0.263"
        d="M1418.855 596.125v229.389l527.196-507.585v-317.413z"
      />
      <path
        fill="#d8d8d8"
        opacity="0.263"
        d="M891.66 319.122l527.197 506.394v-229.391l-527.197-595.611z"
      />
    </svg>
  );
}
