import React from "react";

export default function Computer({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 154.3 107.8"
      className={className}
    >
      <g>
        <path
          fill="currentColor"
          className="st0"
          d="M131.6,35.8h-3.8c-0.9,0-1.7-0.5-2.1-1.2L120,24.3c-1.4-2.7-4.2-4.3-7.2-4.3H79.1c-3,0-5.8,1.6-7.2,4.3
		l-5.6,10.3c-0.4,0.8-1.2,1.2-2.1,1.2h-3.8c-1.8,0-3.2,1.4-3.2,3.2v4c0,1.3,0.8,2.4,1.9,2.9l1.4,0.8c0.4,0.2,0.7,0.7,0.7,1.2v20.5
		c0,3.8,3.1,6.8,6.8,6.8h2.7c3.8,0,6.8-3.1,6.8-6.8v-0.7h36.9v0.7c0,3.8,3.1,6.8,6.8,6.8h2.7c3.8,0,6.8-3.1,6.8-6.8V47.9
		c0-0.5,0.3-0.9,0.7-1.2l1.4-0.8c1.1-0.5,1.9-1.6,1.9-2.9v-4C134.7,37.3,133.3,35.9,131.6,35.8z M130.8,42.5l-1.3,0.7
		c-1.7,1-2.7,2.7-2.7,4.7v20.5c0,1.6-1.3,2.8-2.8,2.8h-2.7c-1.6,0-2.8-1.3-2.8-2.8v-0.7h3.1c1.1,0,2-0.9,2-2s-0.9-2-2-2h-5.1H75.5h0
		h-5.1c-1.1,0-2,0.9-2,2s0.9,2,2,2h3.1v0.7c0,1.6-1.3,2.8-2.8,2.8H68c-1.6,0-2.8-1.3-2.8-2.8V47.9c0-1.9-1-3.7-2.7-4.7l-1.3-0.7
		v-2.6h3c2.3,0,4.5-1.3,5.6-3.3l5.6-10.3c0.7-1.4,2.2-2.2,3.7-2.2h33.7c1.6,0,3,0.8,3.7,2.2l5.6,10.3c1.1,2,3.3,3.3,5.6,3.3h3V42.5z
		"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M44,51.3H23.9c-1.1,0-2,0.9-2,2s0.9,2,2,2H44c1.1,0,2-0.9,2-2S45.1,51.3,44,51.3z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M44,59H23.9c-1.1,0-2,0.9-2,2s0.9,2,2,2H44c1.1,0,2-0.9,2-2S45.1,59,44,59z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M44,66.7H23.9c-1.1,0-2,0.9-2,2s0.9,2,2,2H44c1.1,0,2-0.9,2-2S45.1,66.7,44,66.7z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M152.6,91.4h-7.8V6.5c0-3.8-3.1-6.8-6.8-6.8H16.3c-3.8,0-6.8,3.1-6.8,6.8v84.9H1.7c-1.1,0-2,0.9-2,2v7.6
		c0,3.9,3.2,7.1,7.1,7.1h140.7h0c3.9,0,7.1-3.2,7.1-7.1v-7.6C154.6,92.3,153.7,91.4,152.6,91.4z M13.5,6.5c0-1.6,1.3-2.8,2.8-2.8
		h121.6c1.6,0,2.8,1.3,2.8,2.8v84.9H13.5V6.5z M150.6,101c0,1.7-1.4,3.1-3.1,3.1H6.8c-1.7,0-3.1-1.4-3.1-3.1v-5.6h7.8h131.3h7.8V101
		z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M44.5,20H23.3c-1.1,0-2,0.9-2,2v21.2c0,1.1,0.9,2,2,2h21.2c1.1,0,2-0.9,2-2V22C46.5,20.9,45.6,20,44.5,20z
		 M42.5,41.2H25.3V24h17.2V41.2z"
        />
      </g>
    </svg>
  );
}
