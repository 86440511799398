import React, { createRef, useState, useEffect, useContext } from "react";
import PreviewAlert from "components/PreviewAlert";
import FooterMenu from "components/Menu/FooterMenu";
import NodeBannerList from "components/Banner/NodeBannerSlider";
import classNames from "lib/classNames";
import { ResizeContext, ResizeProvider } from "contexts/ResizeContext";
import BasicBlock from "components/Block/BasicBlock";
import Image from "next/image";
import Newsletter from "components/Newsletter/Newsletter";
import NodeCommitmentsList from "components/Commitments/NodeCommitmentList";
import SocialNetworksMenu from "components/Menu/SocialNetworksMenu";
import Form from "components/Form";
import Navigation from "components/Navigation";
import Modal from "components/Modal";
import { NodeVehicleListPreviewForSale } from "components/Vehicle/NodeVehicleList";
import Link from "next/link";
import Wheel from "components/Icons/Wheel";
import Phone from "components/Icons/Phone";
import Letter from "components/Icons/Letter";

export const DESKTOP_BREAKPOINTS = ["lg", "xl", "2xl"];

export default function Layout({
  children,
  globals,
  banners,
  home = false,
  list = false,
  showCommitments = true,
  blackMenu = false,
  forSale = null,
}) {
  const mainRef = createRef();
  const topRef = createRef();

  const scrollTop = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
  };

  const { breakpoint } = useContext(ResizeContext);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (breakpoint) {
      setMobile(!DESKTOP_BREAKPOINTS.includes(breakpoint));
    }
  }, [breakpoint]);

  return (
    <ResizeProvider>
      <PreviewAlert />
      <header className="relative transition-all z-50" ref={topRef}>
        <Navigation
          blackMenu={blackMenu}
          globals={globals}
          isHome={home}
          scrollTop={scrollTop}
        />
        {banners?.length > 0 && (
          <NodeBannerList
            nodes={banners}
            scrollDownRef={mainRef}
            globals={globals}
            isHome={home}
          />
        )}
        <div className="hidden sm:block fixed bottom-[40%] left-4 z-50">
          <div className="flex flex-col w-full justify-end space-y-1">
            <Link href="/a-vendre" passHref>
              <a className="bg-white hover:bg-primary border text-primary hover:text-white transition-colors h-9 w-9 rounded-full p-1.5">
                <Wheel className="w-full h-full" />
              </a>
            </Link>
            <a
              href="tel:+33974566432"
              className="bg-white hover:bg-primary border text-primary hover:text-white transition-colors h-9 w-9 rounded-full p-1.5"
            >
              <Phone className="w-full h-full" />
            </a>
            <a
              href="#contact"
              className="bg-white hover:bg-primary border text-primary hover:text-white transition-colors h-9 w-9 rounded-full p-1.5"
            >
              <Letter className="w-full h-full" />
            </a>
          </div>
        </div>
      </header>
      <main
        ref={mainRef}
        className={classNames(
          (home || list) && "bg-white",
          "overflow-hidden lg:overflow-visible"
        )}
      >
        {children}
      </main>
      {showCommitments && globals?.blocks?.commitments?.length && (
        <NodeCommitmentsList nodes={globals.blocks.commitments} />
      )}

      {forSale?.length > 0 && <NodeVehicleListPreviewForSale nodes={forSale} />}

      {globals?.newsletter.id !== null && globals?.newsletter.data !== null && (
        <Newsletter
          id={globals.newsletter.id}
          dataFields={globals.newsletter.data}
          socialNetworkMenu={globals.menus.socialnetworks}
          mobile={mobile}
        />
      )}

      <footer className="bg-black text-white relative">
        <div className="container mx-auto px-4 border-b border-secondary">
          <div className="py-16 grid grid-cols-1 lg:grid-cols-4 gap-16">
            <div className="flex flex-col items-center lg:items-start">
              <div className="w-full text-center lg:text-left">
                <Image
                  src="/images/motors-legend-logo.png"
                  alt="Logo Motors Legend"
                  width={247}
                  height={134}
                  layout="intrinsic"
                />
              </div>
              <div className="flex flex-col items-start space-y-8">
                {globals?.blocks?.footerContact && (
                  <BasicBlock
                    data={globals.blocks.footerContact}
                    className="footer-text"
                    footer
                  />
                )}
                {globals?.menus?.socialnetworks && (
                  <div className="flex justify-center lg:justify-start w-full">
                    <SocialNetworksMenu menu={globals.menus.socialnetworks} />
                  </div>
                )}
              </div>
            </div>
            <div className="lg:pt-[52px]">
              <div className="aspect-h-3 aspect-w-4">
                <iframe
                  title="Motors legend location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d188.38668648196668!2d3.9652462376961295!3d49.052285741818835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e96b58c369211b%3A0x7aa7b7015fca87ff!2s64%20Av.%20Alfred%20Anatole%20Th%C3%A9venet%2C%2051530%20Magenta!5e0!3m2!1sfr!2sfr!4v1659427072764!5m2!1sfr!2sfr"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  className="w-full h-full px-4 lg:px-0"
                />
              </div>
            </div>
            <div className="lg:col-span-2">
              {globals?.contact.id !== null && globals?.contact.data !== null && (
                <div id="contact">
                  <h1 className="uppercase text-3xl mb-4">Nous contacter</h1>
                  <Form
                    id={globals.contact.id}
                    dataFields={globals.contact.data}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container mx-auto px-4 py-2">
          <div className="container flex flex-col lg:flex-row items-center lg:justify-between py-6 mx-auto">
            <p className="text-sm text-white/75 text-center lg:text-start">
              © MOTORS LEGENDS. TOUS DROITS RÉSERVÉS. DÉVELOPPEMENT :&nbsp;
              <a
                href="https://champagne-creation.fr/"
                target="_blank"
                rel="noreferrer"
                className="hover:text-white"
              >
                CHAMPAGNE-CRÉATION.FR
              </a>
            </p>
            {globals?.menus?.footer && (
              <FooterMenu menu={globals.menus.footer} />
            )}
          </div>
        </div>
      </footer>
      <Modal />
    </ResizeProvider>
  );
}
