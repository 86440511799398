import React from "react";

export default function Instagram({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38.8 38.8"
      className={className}
    >
      <g>
        <path
          fill="currentColor"
          className="st0"
          d="M22.9,11.8H17c-2.7,0-4.9,2.2-4.9,4.9v5.9c0,2.7,2.2,4.9,4.9,4.9h5.9c2.7,0,4.9-2.2,4.9-4.9v-5.9
		C27.8,14,25.6,11.8,22.9,11.8z M26.3,22.5c0,1.9-1.5,3.4-3.4,3.4H17c-1.9,0-3.4-1.5-3.4-3.4v-5.9c0-1.9,1.5-3.4,3.4-3.4h5.9
		c1.9,0,3.4,1.5,3.4,3.4V22.5z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M20,15.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9v0C23.9,17.5,22.1,15.7,20,15.7z
		 M20,22.1c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4C22.4,21,21.3,22.1,20,22.1z"
        />
        <circle
          fill="currentColor"
          className="st0"
          cx="24.2"
          cy="15.4"
          r="0.5"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M19.4,0.5V1c5.1,0,9.7,2.1,13,5.4c3.3,3.3,5.4,7.9,5.4,13c0,5.1-2.1,9.7-5.4,13c-3.3,3.3-7.9,5.4-13,5.4
		c-5.1,0-9.7-2.1-13-5.4C3.1,29.1,1,24.5,1,19.4c0-5.1,2.1-9.7,5.4-13C9.7,3.1,14.3,1,19.4,1L19.4,0.5V0C8.7,0,0,8.7,0,19.4h0
		c0,10.7,8.7,19.4,19.4,19.4c10.7,0,19.4-8.7,19.4-19.4C38.8,8.7,30.1,0,19.4,0V0.5z"
        />
      </g>
    </svg>
  );
}
