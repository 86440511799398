import React, { createRef, useEffect, useState } from "react";
import classNames from "lib/classNames";
import Branding from "components/Branding";
import MainMenu from "components/Menu/MainMenu";
import Image from "next/image";
import ScrollTop from "components/ScrollTop";
import { Transition } from "@headlessui/react";

export default function Navigation({
  blackMenu = false,
  globals,
  isHome = false,
  scrollTop,
}) {
  const affixRef = createRef();
  const [scrollDir, setScrollDir] = useState("none");
  const [globalScrollY, setGlobalScrollY] = useState(0);

  useEffect(() => {
    const threshold = 123;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      setGlobalScrollY(scrollY);

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return undefined;
      }

      setScrollDir(scrollY > lastScrollY ? "down" : "up");

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;

      return undefined;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  return (
    <div
      ref={affixRef}
      className={classNames(
        "absolute lg:fixed top-0 inset-x-0 px-4 md:px-16 z-50 transition-all pt-8 pb-16",
        globalScrollY !== 0 ? "pb-8 lg:pb-8" : "pb-8 xl:pb-12 2xl:pb-16",
        blackMenu
          ? "bg-black text-white"
          : !isHome &&
              "bg-black text-white lg:bg-white lg:text-black shadow-md",
        isHome && globalScrollY === 0
          ? "bg-black lg:bg-transparent lg:text-white"
          : !blackMenu && "lg:bg-white shadow",
        globalScrollY !== 0 && "lg:bg-white lg:text-black shadow"
      )}
    >
      <div className="relative">
        <div className="hidden lg:block lg:absolute -top-8 z-20">
          <Branding menu globalScrollY={globalScrollY} />
        </div>
        {globals?.menus?.main && (
          <MainMenu
            menu={globals.menus.main}
            blackMenu={blackMenu}
            globalScrollY={globalScrollY}
            isHome={isHome}
          />
        )}
        <div className="hidden xl:block xl:absolute -top-8 right-0 xl:z-20">
          <div className="aspect-w-2 aspect-h-1 w-44">
            <a
              href="https://motorslegend-occasions.espacevo.fr/"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src="/images/logo-la-centrale.png"
                layout="fill"
                objectFit="contain"
                alt="Logo la centrale"
              />
            </a>
          </div>
        </div>
      </div>
      <Transition
        show={
          typeof window !== "undefined" && globalScrollY > window.innerHeight
        }
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <ScrollTop scrollTop={scrollTop} />
      </Transition>
    </div>
  );
}
