import React from "react";
import classNames from "lib/classNames";
import Previous from "components/Icons/Previous";

export default function SlidePrevButton({
  theme,
  size = "default",
  disabled = false,
  swiper,
}) {
  let buttonClass;
  switch (theme) {
    case "outline-white":
      buttonClass = !disabled
        ? "hover:border-white text-white/75 hover:text-white border-white/75"
        : "text-white/30 border-white/30";
      break;
    case "outline-dark":
      buttonClass = !disabled
        ? "border-black hover:text-black hover:text-black/60 hover:border-black hover:border-black/60"
        : "text-black/20 border-black/20";
      break;
    case "outline-primary":
      buttonClass = !disabled
        ? "border-primary/50 hover:text-primary hover:border-primary text-primary/50"
        : "border-primary/20 text-primary/20";
      break;
    default:
      buttonClass = !disabled
        ? "border-primary/50 hover:text-primary hover:border-primary text-primary/50"
        : "border-primary/20 text-primary/20";
  }

  const iconSizes = {
    default: "w-2.5 h-2.5 lg:w-4 lg:h-4 2xl:w-5 2xl:h-5",
    small: "w-2.5 h-2.5",
    medium: "w-2.5 h-2.5 lg:w-4 lg:h-4",
  };

  const buttonSizes = {
    default: "p-2.5 lg:p-3.5 2xl:p-5",
    medium: "p-2.5 lg:p-3.5",
    small: "p-2.5",
  };

  return (
    <button
      className={classNames(
        buttonClass,
        buttonSizes[size],
        "flex items-center justify-center rounded-full border transition-all",
        !disabled ? "cursor-pointer" : ""
      )}
      disabled={disabled}
      onClick={() => swiper.slidePrev()}
      type="button"
    >
      <Previous className={classNames("block", iconSizes[size])} />
    </button>
  );
}
