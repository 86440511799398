import React from "react";

export default function Car({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 180.6 127.6"
      className={className}
    >
      <g>
        <path
          fill="currentColor"
          className="st0"
          d="M102.5,46.2l14.6-2c1.2-0.2,2.1-1.3,1.9-2.6c-0.2-1.2-1.3-2.1-2.6-1.9l-14.6,2c-4.8,0.7-8.3,4.8-8.3,9.7V55
		c0,1.3,1,2.3,2.3,2.3h7.8c1.3,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3H98v-1.4C98,48.7,100,46.5,102.5,46.2z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M12.9,52.7c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3h21c1.3,0,2.3-1,2.3-2.3v-3.7c0-4.8-3.6-9-8.4-9.7l-14.6-2
		c-1.2-0.2-2.4,0.7-2.6,1.9c-0.2,1.2,0.7,2.4,1.9,2.6l14.6,2c2.5,0.4,4.5,2.6,4.5,5.1v1.4H12.9z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M79.6,34.1c3.1,0,6.1-1.2,8.4-3.3l4.7-4.3c0.9-0.8,1-2.3,0.1-3.2c-0.8-0.9-2.3-1-3.2-0.1l-4.7,4.3
		c-1.5,1.3-3.3,2.1-5.3,2.1h0l-12.6,0l-16.8,0h0c-2,0-3.9-0.7-5.3-2.1l-4.7-4.3c-0.9-0.8-2.4-0.8-3.2,0.1c-0.8,0.9-0.8,2.4,0.1,3.2
		l4.7,4.3c2.3,2.1,5.3,3.3,8.4,3.3h0l16.8,0L79.6,34.1L79.6,34.1z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M95.8,65.5H12.9c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3h7.6c-0.5,4.1-4,7.4-8.3,7.4c-4.6,0-8.4-3.7-8.4-8.4v-1.3
		l0,0l0,0V29.7c0-3.6,2.9-6.5,6.5-6.5h12.5c0.9,0,1.7-0.5,2.1-1.3c2.2-4.9,5.2-9.3,8.8-13.2c2.9-3.1,7-4.8,11.2-4.8h39.7l0.1,0
		c4,0,7.9,1.6,10.7,4.5c3.9,4,7,8.5,9.2,13.6c0.4,0.8,1.2,1.3,2.1,1.3h12.5c3.6,0,6.5,2.9,6.5,6.5V46c0,1.3,1,2.3,2.3,2.3
		c1.3,0,2.3-1,2.3-2.3V29.7c0-4.9-3.2-9.1-7.7-10.5v-4.5c0-4.9-4-8.9-8.9-8.9h-7.5c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3h7.5
		c2.4,0,4.3,1.9,4.3,4.3v4h-9.8c-2.4-5-5.6-9.6-9.5-13.6C95.1,1.4,90-0.7,84.8-0.7l-0.1,0H45c-5.5,0-10.8,2.3-14.5,6.3
		c-3.7,3.9-6.7,8.3-9.1,13.1h-9.8v-4c0-2.4,1.9-4.3,4.3-4.3h7.5c1.3,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3h-7.5C11,5.8,7,9.8,7,14.7v4.5
		c-1.7,0.5-3.2,1.4-4.4,2.7c-2.1,2.1-3.2,4.9-3.2,7.8v38.1l0,0l0,0v1.3c0,7.1,5.8,12.9,12.9,12.9c6.8,0,12.4-5.3,12.9-11.9h70.7
		c1.3,0,2.3-1,2.3-2.3S97,65.5,95.8,65.5z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M57.2,52.7c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3h15.2c1.3,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3H57.2z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M164.8,80c0-17.6-14.3-31.8-31.8-31.8S101.1,62.4,101.1,80c0,17.6,14.3,31.8,31.8,31.8S164.8,97.5,164.8,80z
		 M133,107.3c-15,0-27.3-12.2-27.3-27.3c0-15,12.2-27.3,27.3-27.3s27.3,12.2,27.3,27.3C160.3,95,148,107.3,133,107.3z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M179.5,117.8l-14.4-14.4c4.2-5.9,6.6-13,6.6-20.8c0-1.3-1-2.3-2.3-2.3s-2.3,1-2.3,2.3
		c0,17.4-14.2,31.6-31.6,31.6c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3c7.7,0,14.9-2.4,20.8-6.6l14.4,14.4c1.2,1.2,2.8,1.8,4.3,1.8
		s3.1-0.6,4.3-1.8C181.8,124.1,181.9,120.2,179.5,117.8z M176.2,123.3c-0.6,0.6-1.6,0.6-2.3,0l-14.1-14.1c0.8-0.7,1.6-1.5,2.3-2.3
		l14,14C176.9,121.6,176.9,122.6,176.2,123.3z"
        />
      </g>
    </svg>
  );
}
