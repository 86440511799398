import React from "react";

export default function Handshake({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 160 114"
      className={className}
    >
      <g>
        <path
          fill="currentColor"
          d="M159,58.6c-0.5-1-1.7-1.4-2.7-0.9l-12.8,6.6l-1.8-3.1l0,0l-25.3-43.7l23.5-13.5c1-0.6,1.3-1.8,0.7-2.7
		c-0.6-1-1.8-1.3-2.7-0.7L112.8,15c-0.5,0.3-0.8,0.7-0.9,1.2c-0.1,0.5-0.1,1.1,0.2,1.5l1.8,3.1l-9.4,5.4l-3.2-1.3
		c-6.8-2.7-14.3-2.7-21.2-0.1c-6.8-2.6-14.3-2.5-21.1,0.1l-3.2,1.3l-9.4-5.4l1.8-3.1c0.3-0.5,0.3-1,0.2-1.5
		c-0.1-0.5-0.5-0.9-0.9-1.2L22.1,0.4c-1-0.6-2.2-0.2-2.7,0.7c-0.6,1-0.2,2.2,0.7,2.7l23.5,13.5L18.3,61.1l0,0l-1.8,3.1L3.7,57.7
		c-1-0.5-2.2-0.1-2.7,0.9c-0.5,1-0.1,2.2,0.9,2.7l14.5,7.4c0.3,0.2,0.6,0.2,0.9,0.2c0.7,0,1.4-0.4,1.7-1l1.8-3.1l8.3,4.8l-2.1,1.2
		c-4.2,2.5-5.7,7.9-3.2,12.2l0.1,0.2c1.6,2.8,4.5,4.3,7.6,4.3c0.9,0,1.9-0.2,2.8-0.5c-0.2,1.8,0.2,3.6,1.2,5.3
		c1.7,2.8,4.6,4.4,7.7,4.4c0.9,0,1.9-0.2,2.8-0.5c-0.2,1.8,0.2,3.6,1.2,5.3l0.1,0.2c1.6,2.8,4.5,4.3,7.6,4.3c1.5,0,3-0.4,4.3-1.2
		l0.9-0.5c-0.1,1.7,0.3,3.4,1.2,5l0.1,0.1c1.6,2.8,4.5,4.3,7.6,4.3c0.9,0,1.8-0.1,2.6-0.4c0.3,0,0.6-0.1,0.9-0.3l0.8-0.5l0,0l0,0
		l0,0l0.1-0.1l0,0l2.6-1.5l0,0l0.1,0l4.2-2.4l7,4c1.4,0.8,2.9,1.2,4.3,1.2c3,0,5.9-1.6,7.6-4.4l0-0.1l0-0.1c0.9-1.5,1.3-3.3,1.2-5
		l0.9,0.5c1.4,0.8,2.9,1.2,4.3,1.2c3,0,5.9-1.6,7.6-4.3l0.1-0.2c0.9-1.6,1.3-3.5,1.2-5.2c0.9,0.3,1.8,0.5,2.8,0.5
		c3.1,0,6.1-1.6,7.7-4.4c1-1.7,1.3-3.5,1.2-5.3c0.9,0.3,1.8,0.5,2.8,0.5c3,0,5.9-1.6,7.6-4.4l0.1-0.1l0.1-0.1
		c2.5-4.3,1-9.7-3.3-12.2c-0.6-0.4-1.3-0.7-2-1.2l8.3-4.8l1.8,3.1c0.4,0.6,1,1,1.7,1c0.3,0,0.6-0.1,0.9-0.2l14.5-7.4
		C159.1,60.8,159.5,59.6,159,58.6z M33.7,82.9c-2.2,1.3-5.1,0.5-6.4-1.7L27.2,81c-0.7-1.1-0.8-2.5-0.5-3.7c0.3-1.3,1.1-2.3,2.3-3
		l4.3-2.5l0.1-0.1l0.1-0.1l0.2-0.1c2.3-1.3,5.3-0.5,6.6,1.8c1.4,2.3,0.6,5.3-1.7,6.7l-0.2,0.1l0,0l0,0l0,0l0,0l0,0l-0.7,0.4
		L33.7,82.9z M49.2,89.9l-3.8,2.2c-2.3,1.3-5.3,0.5-6.6-1.8c-1.4-2.3-0.6-5.3,1.7-6.7l0.2-0.1l0,0l0,0l0,0l0,0l1.1-0.7l0,0l2.4-1.4
		l0,0l0,0c1.1-0.7,2.4-0.8,3.7-0.5c1.3,0.3,2.3,1.1,3,2.3c1,1.8,0.8,4.1-0.5,5.7l0,0l0,0C50,89.3,49.6,89.6,49.2,89.9L49.2,89.9z
		 M62.9,96.7L62.9,96.7L62.9,96.7c-0.4,1-1.1,1.8-2,2.4l0,0l-3.9,2.3c-2.2,1.3-5.1,0.5-6.4-1.7l-0.1-0.2c-1.4-2.3-0.6-5.3,1.8-6.7
		l0,0l1.2-0.7l0,0l0.2-0.1l0,0l2.4-1.4l0,0l0,0c1.1-0.7,2.4-0.8,3.7-0.5c1.3,0.3,2.3,1.1,3,2.3C63.4,93.7,63.5,95.3,62.9,96.7z
		 M76,104.6L76,104.6L76,104.6c-0.3,1.2-1.1,2.3-2.2,2.9l0,0l0,0l-2.6,1.5l0,0l0,0l-0.1,0.1l0,0c-2.2,1.3-5.1,0.5-6.4-1.7l-0.1-0.2
		c-1.4-2.3-0.6-5.3,1.7-6.7l0,0l0,0l0,0l0.8-0.5l0.1-0.1l0,0l1.7-1c1.1-0.7,2.5-0.8,3.7-0.5C75.2,99.3,76.7,102,76,104.6z
		 M131.1,74.3c2.3,1.3,3.1,4.4,1.8,6.7l-0.1,0.1c-1.3,2.2-4.2,3-6.4,1.7l-4.8-2.8l0,0L96.2,65.5c-1-0.6-2.2-0.2-2.7,0.7
		c-0.6,1-0.2,2.2,0.7,2.7l25.3,14.6c2.3,1.4,3.1,4.4,1.7,6.7c-1.4,2.3-4.3,3.1-6.6,1.8l-4.8-2.7l0,0l0,0l-0.2-0.1l-0.9-0.5l0,0l0,0
		L90.5,78.2l0,0l-0.8-0.4l0,0l-0.4-0.2c-1-0.6-2.2-0.2-2.7,0.7c-0.2,0.3-0.3,0.7-0.3,1c0,0.7,0.4,1.4,1,1.7l0.4,0.2l0,0l0.8,0.4l0,0
		l18.1,10.5l0,0l1.2,0.7l0,0c2.3,1.3,3.1,4.3,1.8,6.7l-0.1,0.2c-1.3,2.2-4.2,3-6.4,1.7l-7.3-4.2l0,0l-0.7-0.4l-0.2-0.1l0,0l0,0
		l-7.4-4.3l-6.1-3.5l0,0l0,0l-0.1-0.1l0,0l-0.8-0.4l0,0l0,0l-0.1-0.1l0,0L80,88.1c-1-0.6-2.2-0.2-2.7,0.7S77,90.9,78,91.5l0.3,0.2
		l0,0l0.1,0.1l0,0l0.8,0.4l0,0l0,0l0,0l0,0l0.1,0l0,0l0,0l0,0l6.1,3.5l7.4,4.3l0,0l0.9,0.5l0,0c1.1,0.7,1.9,1.7,2.3,3
		s0.2,2.6-0.5,3.7l0,0l-0.1,0.1c-1.3,2.2-4.2,3-6.4,1.7l-8-4.6l0,0l-0.8-0.5c0.4-4.2-2.3-8.2-6.6-9.4c-1.3-0.4-2.7-0.4-4-0.1
		c-0.5-0.1-1,0.1-1.4,0.3l0,0l-0.5,0.3l-0.1,0.1l-0.3,0.2c0.1-1.7-0.3-3.5-1.2-5.1c-2-3.5-6.1-5.1-9.8-4.1c-0.2,0-0.5,0-0.7,0.1
		c0.1-1.7-0.2-3.5-1.2-5.1c-1.2-2.1-3.1-3.5-5.4-4.1c-1.3-0.4-2.7-0.4-4-0.1c0.2-1.9-0.1-3.9-1.2-5.7c-2.2-3.7-6.8-5.2-10.7-3.8
		L33,67.3l-10.2-5.9l21.5-37.2l10.2,5.9c0.3,0.2,0.7,0.3,1,0.3c0.2,0,0.5,0,0.7-0.1l4.1-1.6c4.6-1.8,9.5-2.2,14.3-1.3l-12.9,7.4
		c-3.5,2-6.1,5.4-7,9.2l-3.4,13.4c-0.4,1.4-0.1,2.9,0.6,4.1c0.7,1.2,1.9,2.1,3.3,2.5c0.4,0.1,0.9,0.2,1.3,0.2h0
		c4.9,0,9.3-2.9,11.3-7.4l2.4-5.5l5.2-3.5c1.7,0.3,6.2,1.1,12.6,1.7l4.3,2.5l0,0c7.3,4.2,16.4,9.5,24.3,14l10,5.8l0,0l0.8,0.5
		C128.8,73,130,73.7,131.1,74.3z M127,67.3L127,67.3l-8.3-4.8c-6.9-4-14.7-8.5-21.4-12.4c3.6,0.2,7.3,0.3,10.9,0.3c1.1,0,2-0.9,2-2
		s-0.9-2-2-2c-6.4,0-12.9-0.3-19.2-0.9l-0.1,0l0,0c-8.3-0.8-13.3-1.8-13.4-1.9c-0.5-0.1-1.1,0-1.5,0.3l-6.4,4.3
		c-0.3,0.2-0.6,0.5-0.7,0.9l-2.6,6c-1.3,3-4.3,5-7.6,5c-0.1,0-0.2,0-0.3,0c-0.4-0.1-0.7-0.3-0.9-0.7c-0.2-0.3-0.3-0.7-0.2-1.1
		L58.5,45c0.7-2.8,2.6-5.3,5.1-6.8l8.8-5l5-2.9c1-0.6,2.1-1.1,3.2-1.5l0.1,0c6.1-2.5,12.8-2.5,18.9-0.1l4.1,1.6
		c0.2,0.1,0.5,0.1,0.7,0.1c0.3,0,0.7-0.1,1-0.3l10.2-5.9l21.5,37.2L127,67.3z"
        />
      </g>
    </svg>
  );
}
