import React from "react";
import Link from "next/link";

export default function FooterMenu({ menu }) {
  return (
    menu && (
      <ul className="flex flex-wrap lg:flex-nowrap justify-center items-center lg:space-x-12 text-white/75 mt-4 px-8 lg:px-0 lg:mt-0">
        {menu.map((item) => {
          return (
            <li
              key={item.id}
              className="flex-[0_0_50%] lg:flex-1 flex justify-center w-full"
            >
              <Link href={item.url} passHref>
                <a className="text-sm hover:text-white whitespace-nowrap">
                  {item.title}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    )
  );
}
