import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { ExclamationIcon } from "@heroicons/react/outline";

export default function PreviewAlert() {
  const { isPreview } = useRouter();
  const [showPreviewAlert, setShowPreviewAlert] = useState(false);

  useEffect(() => {
    setShowPreviewAlert(isPreview && window.top === window.self);
  }, [isPreview]);

  if (!showPreviewAlert) {
    return null;
  }

  return (
    <div className="fixed bottom-8 left-8 z-50 flex items-center px-4 py-2 text-white bg-black rounded shadow">
      <ExclamationIcon className="inline-block h-6 w-6 mr-2" />
      <p className="mb-0">
        Mode aperçu,{" "}
        <Link href="/api/exit-preview" passHref prefetch={false}>
          <a className="text-white underline">quitter le mode aperçu</a>
        </Link>
      </p>
    </div>
  );
}
