import React, { useContext, useEffect, useState } from "react";
import Button from "components/Button";
import Next from "components/Icons/Next";
import Previous from "components/Icons/Previous";
import { ResizeContext } from "contexts/ResizeContext";
import { DESKTOP_BREAKPOINTS } from "components/Layout";

export default function PagerStatic({
  current,
  total,
  show = 5,
  scrollTopPaginate,
  setCurrentPage,
  setUserInteraction,
  userInteraction,
}) {
  const { breakpoint } = useContext(ResizeContext);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (breakpoint) {
      setMobile(!DESKTOP_BREAKPOINTS.includes(breakpoint));
    }
  }, [breakpoint]);

  const newShow = total < show ? total : show;
  const items = Array(newShow).fill(0);

  return total <= 1 ? null : (
    <div className="flex items-center justify-center space-x-4 mt-16 lg:py-16 relative z-20">
      {current > 1 && (
        <div
          onClick={() => {
            setCurrentPage(Number(current - 1));
            if (!userInteraction) {
              setUserInteraction(true);
            }
            if (mobile) {
              scrollTopPaginate();
            }
          }}
          className="cursor-pointer flex items-center"
        >
          <Button as="a" theme="secondary" size="pagination-next-back" inverse>
            <Previous className="h-4 lg:h-6 w-4 lg:w-6" />
          </Button>
        </div>
      )}

      <div className="block lg:hidden">
        <Button as="span" theme="primary" size="pagination" inverse>
          <span className="font-sans">
            {current} / {total}
          </span>
        </Button>
      </div>

      <ul className="hidden lg:flex items-center justify-center space-x-4">
        {items.map((item, index) => {
          const page = index + 1;
          return (
            <li key={item.id}>
              <div
                onClick={() => {
                  setCurrentPage(Number(page));
                  if (!userInteraction) {
                    setUserInteraction(true);
                  }
                }}
                className="cursor-pointer"
              >
                <Button
                  as="a"
                  theme="pagination"
                  size="pagination"
                  inverse={page !== current}
                >
                  <span className="block h-4 w-4 text-center leading-5 font-sans">
                    {page}
                  </span>
                </Button>
              </div>
            </li>
          );
        })}
      </ul>
      {current < total && (
        <div
          onClick={() => {
            setCurrentPage(Number(current + 1));
            if (!userInteraction) {
              setUserInteraction(true);
            }
            if (mobile) {
              scrollTopPaginate();
            }
          }}
          className="cursor-pointer flex items-center"
        >
          <Button as="a" theme="secondary" size="pagination-next-back" inverse>
            <Next className="h-4 lg:h-6 w-4 lg:w-6" />
          </Button>
        </div>
      )}
    </div>
  );
}
