import React, { createRef, useRef, useState } from "react";
import NodeVehicleTeaser, {
  NodeVehicleTeaserForSale,
  NodeVehicleTeaserSkeleton,
  NodeVehicleTeaserSold,
} from "components/Vehicle/NodeVehicleTeaser";
import Link from "next/link";
import Button from "components/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import SlidePrevButton from "components/Swiper/SlidePrevButton";
import SlideNextButton from "components/Swiper/SlideNextButton";
import PagerStatic from "components/PagerStatic";
import Motorslegend from "components/Icons/Motorslegend";
import Pager from "../Pager";

export function NodeVehicleListStaticPaginated({
  filteredNodes,
  staticPage,
  isSold = false,
}) {
  return (
    <>
      <div className="grid grid-cols-1 px-4 lg:px-0 lg:grid-cols-3 xl:grid-cols-4 gap-7 gap-y-12 relative">
        {filteredNodes?.length > 0 ? (
          filteredNodes.map((node) => (
            <div key={node.id}>
              {isSold ? (
                <NodeVehicleTeaserSold node={node} fill />
              ) : (
                <NodeVehicleTeaser node={node} fill />
              )}
            </div>
          ))
        ) : (
          <p>Aucun résultat à votre recherche.</p>
        )}
      </div>
      <Pager
        current={staticPage.current}
        total={staticPage.total}
        path={isSold ? "/vendu" : "/a-vendre"}
      />
      {filteredNodes?.length > 0 && (
        <div className="absolute inset-x-0 bottom-0 translate-y-2">
          <Motorslegend className="w-full h-full" />
        </div>
      )}
    </>
  );
}

export function NodeVehicleListDynamicPaginated({
  filteredNodes,
  totalPage,
  loading,
  currentPage,
  setCurrentPage,
  setUserInteraction,
  userInteraction,
  isSold = false,
}) {
  const topPaginate = createRef();

  const scrollTopPaginate = () => {
    if (topPaginate.current) {
      topPaginate.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
  };

  return (
    <>
      <div
        className="grid grid-cols-1 px-4 lg:px-0 lg:grid-cols-3 xl:grid-cols-4 gap-7 gap-y-12 relative"
        ref={topPaginate}
      >
        {loading ? (
          <NodeVehicleListPaginatedSkeleton />
        ) : (
          <>
            {filteredNodes?.length > 0 ? (
              filteredNodes.map((node) => (
                <div key={node.id}>
                  {isSold ? (
                    <NodeVehicleTeaserSold node={node} fill />
                  ) : (
                    <NodeVehicleTeaser node={node} fill />
                  )}
                </div>
              ))
            ) : (
              <div>
                <p className="mb-8">Aucun résultat à votre recherche.</p>
                <a href="/a-vendre">
                  <Button size="small">Réinitialiser les filtres</Button>
                </a>
              </div>
            )}
          </>
        )}
      </div>
      {!loading && (
        <PagerStatic
          current={currentPage}
          total={totalPage}
          scrollTopPaginate={scrollTopPaginate}
          setCurrentPage={setCurrentPage}
          setUserInteraction={setUserInteraction}
          userInteraction={userInteraction}
        />
      )}
      {filteredNodes?.length > 0 && (
        <div className="absolute inset-x-0 bottom-0 translate-y-2">
          <Motorslegend className="w-full h-full" />
        </div>
      )}
    </>
  );
}

export function NodeVehicleListPaginatedSkeleton({}) {
  return [...Array(Number(process.env.NEXT_PUBLIC_ITEMS_PER_PAGE)).keys()].map(
    (index) => <NodeVehicleTeaserSkeleton key={index} />
  );
}

export function NodeVehicleListPreviewSold({ nodes }) {
  const [swiperSold, setSwiperSold] = useState(null);
  const [slideNb, setSlideNb] = useState(0);

  return (
    <div className="pt-10 relative overflow-hidden mb-8 lg:mb-16 lg:pb-8">
      <div className="absolute bottom-0 left-0 w-full h-2/3 bg-black" />
      <div className="absolute bg-motorslegend bg-no-repeat bg-bottom -bottom-[6.5rem] left-0 w-full h-2/3" />
      <div className="relative container mx-auto px-4">
        <div className="py-10 2xl:py-16">
          <h2 className="mb-16 text-center block text-5xl xl:text-7xl 2xl:text-8xl uppercase">
            <span>Nos&nbsp;</span>
            <span className="text-primary">dernières</span>
            <span>&nbsp;ventes</span>
          </h2>
          <div className="px-10 lg:hidden section-slider">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              modules={[Navigation]}
              onSwiper={setSwiperSold}
              onSlideChange={(index) => setSlideNb(index.snapIndex)}
            >
              {nodes.map((node) => (
                <SwiperSlide key={node.id}>
                  <NodeVehicleTeaserSold node={node} wide />
                </SwiperSlide>
              ))}
            </Swiper>
            {swiperSold && nodes.length > 1 && (
              <div className="flex gap-2.5 2xl:gap-8 items-center justify-center mt-10 lg:mt-16">
                <SlidePrevButton
                  theme="outline-white"
                  size="medium"
                  disabled={slideNb === 0}
                  swiper={swiperSold}
                />
                <SlideNextButton
                  theme="outline-white"
                  size="medium"
                  disabled={slideNb === nodes.length - 1}
                  swiper={swiperSold}
                />
              </div>
            )}
          </div>
          <div className="hidden lg:grid lg:grid-cols-3 xl:grid-cols-4 lg:gap-7 relative z-0">
            {nodes.map((node) => (
              <div key={node.id}>
                <NodeVehicleTeaserSold node={node} wide />
              </div>
            ))}
          </div>
          <div className="text-center mt-8 lg:mt-16">
            <Link href="/vendu" passHref>
              <Button as="a" theme="white">
                Découvrir
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export function NodeVehicleListPreviewForSale({ nodes }) {
  const nextRefForSale = useRef();
  const prevRefForSale = useRef();
  const [swiperForSale, setSwiperForSale] = useState(null);
  const [slideNb, setSlideNb] = useState(0);

  return (
    <div className="relative bg-forsale bg-[center_left_55rem] lg:bg-center pt-16 overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="pb-8 lg:py-16 section-slider">
          <div className="relative -mb-8 lg:mb-0">
            <h2 className="text-white block uppercase flex flex-col text-center lg:text-left items-center lg:items-start">
              <span className="text-sm lg:text-xl 2xl:text-2xl bg-primary px-4 py-2.5 w-max tracking-widest font-semibold font-sans">
                Nos véhicules
              </span>
              <span className="text-white text-5xl lg:text-7xl 2xl:text-8xl pt-4 lg:pt-8">
                à vendre
              </span>
            </h2>

            <div className="hidden lg:flex absolute top-20 right-0 gap-2.5 2xl:gap-8 items-center justify-center">
              {swiperForSale && nodes.length > 3 && (
                <>
                  <SlidePrevButton
                    prevRef={prevRefForSale}
                    theme="outline-white"
                    disabled={slideNb === 0}
                    swiper={swiperForSale}
                  />
                  <SlideNextButton
                    nextRef={nextRefForSale}
                    theme="outline-white"
                    disabled={slideNb === nodes.length - 3}
                    swiper={swiperForSale}
                  />
                </>
              )}
            </div>
          </div>
          <Swiper
            slidesPerView={3}
            spaceBetween={40}
            modules={[Navigation]}
            onSwiper={setSwiperForSale}
            onSlideChange={(index) => setSlideNb(index.snapIndex)}
            className="lg:overflow-visible"
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1280: {
                slidesPerView: 3,
              },
            }}
          >
            {nodes.map((node) => (
              <SwiperSlide key={node.id}>
                <NodeVehicleTeaserForSale node={node} wide />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="lg:hidden flex gap-2.5 2xl:gap-8 items-center justify-center relative z-20 pt-8 lg:pt-0">
            {swiperForSale && nodes.length > 1 && (
              <>
                <SlidePrevButton
                  theme="outline-white"
                  size="medium"
                  disabled={slideNb === 0}
                  swiper={swiperForSale}
                />
                <SlideNextButton
                  theme="outline-white"
                  size="medium"
                  disabled={slideNb === nodes.length - 1}
                  swiper={swiperForSale}
                />
              </>
            )}
          </div>

          <div className="text-center mt-10 relative z-20">
            <Link href="/a-vendre" passHref>
              <Button as="a" theme="white">
                Découvrir
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
