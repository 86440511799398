import classNames from "lib/classNames";
import useField from "lib/useField";
import React from "react";

export default function Textarea({ register, errors, field }) {
  const { id, name, disabled, required, requiredMessage, title, placeholder } =
    useField(field);
  const hasError = errors[name] || false;

  return (
    <>
      <label htmlFor={id} className="sr-only">
        {title}
      </label>
      {hasError && (
        <p className="text-xs lg:text-sm text-red-500 mb-2 font-semibold">
          {errors[name].message}
        </p>
      )}
      <textarea
        id={id}
        {...register(name, {
          required: required && requiredMessage,
          disabled,
        })}
        rows={field["#rows"] ? field["#rows"] : 5}
        placeholder={placeholder || title}
        className={classNames(
          hasError ? "border-red-500" : "border-black/30",
          "w-full border p-4 text-black text-xs lg:text-sm"
        )}
      />
    </>
  );
}
