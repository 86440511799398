import React, { forwardRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = forwardRef(({ field }, ref) => {
  return (
    <div id={field["#webform_id"]}>
      <ReCAPTCHA
        ref={ref}
        size="invisible"
        sitekey={process.env.NEXT_PUBLIC_CAPTCHA_KEY}
      />
    </div>
  );
});

Captcha.displayName = "Captcha";
export default Captcha;
