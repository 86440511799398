import React from "react";

export default function Facebook({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 39.1 39.1"
      className={className}
    >
      <g>
        <path
          fill="currentColor"
          className="st0"
          d="M19.6,1c-2.5,0-4.9,0.5-7.2,1.5c-2.2,0.9-4.2,2.3-5.9,4c-1.7,1.7-3,3.7-4,5.9C1.5,14.6,1,17,1,19.6
		s0.5,4.9,1.5,7.2c0.9,2.2,2.3,4.2,4,5.9c1.7,1.7,3.7,3,5.9,4c2.3,1,4.7,1.5,7.2,1.5s4.9-0.5,7.2-1.5c2.2-0.9,4.2-2.3,5.9-4
		c1.7-1.7,3-3.7,4-5.9c1-2.3,1.5-4.7,1.5-7.2s-0.5-4.9-1.5-7.2c-0.9-2.2-2.3-4.2-4-5.9c-1.7-1.7-3.7-3-5.9-4C24.5,1.5,22.1,1,19.6,1
		 M19.6,0c10.8,0,19.6,8.8,19.6,19.6s-8.8,19.6-19.6,19.6S0,30.4,0,19.6S8.8,0,19.6,0z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M23.7,11.8h-2c-2-0.1-3.6,1.4-3.7,3.3c0,0.2,0,0.3,0,0.5v1.8h-2c-0.2,0-0.3,0.1-0.3,0.3v0v2.6
		c0,0.2,0.1,0.3,0.3,0.3h2v6.5c0,0.2,0.1,0.3,0.3,0.3h2.7c0.2,0,0.3-0.1,0.3-0.3l0,0v-6.5h2.4c0.2,0,0.3-0.1,0.3-0.3v-2.6
		c0-0.2-0.1-0.3-0.3-0.3h-2.4v-1.5c0-0.7,0.2-1.1,1.1-1.1h1.4c0.2,0,0.3-0.1,0.3-0.3v-2.4C24,12,23.9,11.8,23.7,11.8L23.7,11.8z"
        />
      </g>
    </svg>
  );
}
