import classNames from "lib/classNames";
import useField from "lib/useField";
import React from "react";

export default function EmailNewsletter({ register, errors, field }) {
  const { id, name, disabled, required, requiredMessage } = useField(field);
  const hasError = errors[name] || false;

  return (
    <div className="flex flex-col w-full sm:w-1/3 lg:w-1/2 lg:order-1">
      <input
        id={id}
        name={name}
        type="text"
        {...register(name, {
          required: required && requiredMessage,
          disabled,
        })}
        placeholder={field["#description"]}
        className={classNames(
          hasError ? "border-red-500" : "border-white",
          "w-full h-full px-2 py-4 xl:py-[13.5px] 2xl:py-[25px] 2xl:px-4 placeholder:text-white text-white bg-black border-2"
        )}
      />
      {hasError && (
        <p className="text-xs lg:text-sm text-red-500 font-semibold">
          {errors[name].message}
        </p>
      )}
    </div>
  );
}
