import React from "react";

export default function Letter({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 47.371 36.028"
      className={className}
    >
      <g transform="translate(1 1)">
        <path
          d="M-1-1H46.371V35.028H-1ZM44.371,1H1V33.028H44.371Z"
          transform="translate(0 0)"
          fill="currentColor"
        />
        <path
          d="M163.871,184.266,141.2,168.079l1.162-1.628,21.522,15.363L186.066,166.2l1.151,1.635Z"
          transform="translate(-141.262 -166.856)"
          fill="currentColor"
        />
        <path
          d="M-.062,10.993l-1.35-1.475L10.528-1.413,11.878.062Z"
          transform="translate(1.328 23.307)"
          fill="currentColor"
        />
        <path
          d="M10.54,10.993-1.413.063l1.35-1.476L11.89,9.517Z"
          transform="translate(33.572 23.307)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
