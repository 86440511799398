import React from "react";

export default function Linkedin({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38.8 38.8"
      className={className}
    >
      <g>
        <path
          fill="currentColor"
          className="st0"
          d="M27.9,27.4L27.9,27.4v-5.7c0-2.8-0.6-5-3.9-5c-1.3,0-2.4,0.6-3.1,1.7h0V17h-3.1v10.4h3.2v-5.2
		c0-1.4,0.3-2.7,1.9-2.7c1.7,0,1.7,1.5,1.7,2.8v5.1H27.9z"
        />
        <rect
          fill="currentColor"
          x="12.6"
          y="17"
          className="st0"
          width="3.2"
          height="10.4"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M14.2,11.8c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9s1.9-0.8,1.9-1.9v0C16.1,12.7,15.2,11.8,14.2,11.8z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M19.4,0.5V1c5.1,0,9.7,2.1,13,5.4c3.3,3.3,5.4,7.9,5.4,13c0,5.1-2.1,9.7-5.4,13c-3.3,3.3-7.9,5.4-13,5.4
		c-5.1,0-9.7-2.1-13-5.4C3.1,29.1,1,24.5,1,19.4c0-5.1,2.1-9.7,5.4-13C9.7,3.1,14.3,1,19.4,1L19.4,0.5V0C8.7,0,0,8.7,0,19.4h0
		c0,10.7,8.7,19.4,19.4,19.4c10.7,0,19.4-8.7,19.4-19.4C38.8,8.7,30.1,0,19.4,0V0.5z"
        />
      </g>
    </svg>
  );
}
