import React from "react";

export default function Next({ className }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 556 1024"
      className={className}
    >
      <path
        fill="currentColor"
        d="M512 544.074c-0.025 0-0.054 0-0.083 0-11.921 0-22.732-4.754-30.64-12.47l0.009 0.009-468.123-456.32c-8.175-7.977-13.245-19.104-13.245-31.415 0-11.962 4.787-22.807 12.55-30.722l-0.007 0.007c7.977-8.175 19.104-13.245 31.415-13.245 11.962 0 22.807 4.787 30.722 12.55l-0.007-0.007 468.123 456.32c8.176 7.977 13.247 19.104 13.247 31.416 0 24.233-19.644 43.877-43.877 43.877-0.029 0-0.059 0-0.088 0h0.005z"
      />
      <path
        fill="currentColor"
        d="M43.877 1024.088c-0.024 0-0.053 0-0.081 0-12.271 0-23.365-5.037-31.328-13.156l-0.007-0.007c-7.783-7.913-12.587-18.775-12.587-30.76 0-12.333 5.088-23.477 13.28-31.448l0.010-0.010 468.211-456.232c7.903-7.73 18.73-12.5 30.67-12.5 12.289 0 23.399 5.053 31.364 13.194l0.008 0.008c7.731 7.903 12.501 18.73 12.501 30.671 0 12.333-5.088 23.478-13.28 31.449l-0.010 0.010-468.035 456.232c-7.907 7.758-18.751 12.547-30.713 12.549v0z"
      />
    </svg>
  );
}
