import React from "react";
import Down from "components/Icons/Down";

export default function ScrollTop({ scrollTop }) {
  return (
    <a
      onClick={() => scrollTop()}
      aria-hidden="true"
      className="fixed bottom-0 right-8 lg:right-16 hover:cursor-pointer bg-primary border border-primary rotate-180 px-4 pb-5 pt-4 text-white hover:bg-white hover:text-primary transition-colors z-50"
    >
      <Down className="h-4 w-4" />
    </a>
  );
}
