import React from "react";

export default function Discussion({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 123 129"
      className={className}
    >
      <g>
        <path
          fill="currentColor"
          className="st0"
          d="M32.9,97.8c3.5-2.5,5.9-6.5,5.9-11.1c0-7.5-6.1-13.6-13.6-13.6c-7.5,0-13.6,6.1-13.6,13.6
		c0,4.6,2.3,8.7,5.9,11.2c-7.5,1.6-15.8,7.1-15.8,27.1v2c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-2c0-24.5,12.8-24.7,20.5-24.7l0,0
		l0,0l0.1,0c7.7,0,20.6,0,20.6,24.7v2c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-2C48.9,104.8,40.5,99.3,32.9,97.8z M14.5,86.6
		c0-5.8,4.8-10.6,10.6-10.6s10.6,4.7,10.6,10.6c0,5.8-4.8,10.6-10.6,10.6l0,0C19.3,97.2,14.5,92.5,14.5,86.6z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M105.6,97.8c3.6-2.5,5.9-6.5,5.9-11.2c0-7.5-6.1-13.6-13.6-13.6c-7.5,0-13.6,6.1-13.6,13.6
		c0,4.6,2.3,8.7,5.9,11.1c-7.6,1.5-16,7-16,27.1v2c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-2c0-24.7,12.9-24.7,20.6-24.7l0.1,0l0,0
		l0,0c7.7,0,20.5,0.1,20.5,24.7v2c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-2C121.4,104.9,113.1,99.4,105.6,97.8z M87.2,86.6
		c0-5.8,4.8-10.6,10.6-10.6s10.6,4.7,10.6,10.6c0,5.8-4.8,10.6-10.6,10.6l0,0C92,97.2,87.2,92.5,87.2,86.6z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M100.7,67.6c0.3,0.3,0.7,0.4,1.1,0.4c0.2,0,0.4,0,0.6-0.1c0.6-0.2,0.9-0.8,0.9-1.4V55.9h6.6
		c4.2,0,7.5-3.4,7.5-7.5V18.2c0-4.2-3.4-7.5-7.5-7.5H79.1V8.2c0-4.2-3.4-7.5-7.5-7.5H13.2C9,0.6,5.7,4,5.7,8.2v30.2
		c0,4.2,3.4,7.5,7.5,7.5h4.5v10.6c0,0.6,0.4,1.2,0.9,1.4c0.2,0.1,0.4,0.1,0.6,0.1c0.4,0,0.8-0.2,1.1-0.4l11.6-11.6h12v2.5
		c0,4.2,3.4,7.5,7.5,7.5h37.6L100.7,67.6z M46.9,48.4v-2.5h24.7h0c2,0,3.9-0.8,5.3-2.2c1.4-1.4,2.2-3.3,2.2-5.3V20.2
		c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5v18.1c0,1.2-0.5,2.4-1.3,3.2c-0.9,0.9-2,1.3-3.2,1.3h0H45.4H31.3c-0.4,0-0.8,0.2-1.1,0.4
		l-9.5,9.5v-8.5c0-0.8-0.7-1.5-1.5-1.5h-6c-2.5,0-4.5-2-4.5-4.5V8.2c0-2.5,2-4.5,4.5-4.5h58.4c2.5,0,4.5,2,4.5,4.5v2.5H51.4
		c-4.2,0-7.5,3.4-7.5,7.5v18.1c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5V18.2c0-2.5,2-4.5,4.5-4.5h26.2h32.2c2.5,0,4.5,2,4.5,4.5v30.2
		c0,2.5-2,4.5-4.5,4.5h-8.1c-0.8,0-1.5,0.7-1.5,1.5v8.5l-9.5-9.5c-0.3-0.3-0.7-0.4-1.1-0.4H51.4C48.9,52.9,46.9,50.9,46.9,48.4z"
        />
        <path
          fill="currentColor"
          className="st0"
          d="M68.5,21.2l-11,11l-3-3c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l4,4c0.3,0.3,0.7,0.4,1.1,0.4
		s0.8-0.2,1.1-0.4l12.1-12.1c0.6-0.6,0.6-1.5,0-2.1C70,20.6,69.1,20.6,68.5,21.2z"
        />
      </g>
    </svg>
  );
}
