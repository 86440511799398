import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import Link from "next/link";
import Button from "components/Button";

export default function Pager({ current, total, path, show = 5 }) {
  if (total <= 1) return null;

  const newShow = total < show ? total : show;
  const items = Array(newShow).fill(0);

  return (
    <div className="flex items-center justify-center space-x-4 mt-16 lg:py-16 relative z-20">
      {current > 1 && (
        <Link
          href={current - 1 === 1 ? path : `${path}/pages/${current - 1}`}
          passHref
        >
          <Button as="a" theme="secondary" size="pagination" inverse>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
        </Link>
      )}

      <div className="block lg:hidden">
        <Button as="span" theme="primary" size="pagination" inverse>
          {current} / {total}
        </Button>
      </div>

      <ul className="hidden lg:flex items-center justify-center space-x-4">
        {items.map((item, index) => {
          const page = index + 1;
          return (
            <li key={item.id}>
              <Link href={page === 1 ? path : `${path}/pages/${page}`} passHref>
                <Button
                  as="a"
                  theme="primary"
                  size="pagination"
                  inverse={page !== current}
                >
                  <span className="block h-4 w-4 text-center leading-5">
                    {page}
                  </span>
                </Button>
              </Link>
            </li>
          );
        })}
      </ul>

      {current < total && (
        <Link href={`${path}/pages/${current + 1}`} passHref>
          <Button as="a" theme="secondary" size="pagination" inverse>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </Link>
      )}
    </div>
  );
}
