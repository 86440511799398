import React from "react";
import Car from "components/Icons/Car";
import Computer from "components/Icons/Computer";
import Handshake from "components/Icons/Handshake";
import Discussion from "components/Icons/Discussion";
import Body from "components/Body";

export default function NodeCommitmentTeaser({ commitment, index }) {
  const IconsClassName = "w-[150px] h-[150px]";
  const icons = {
    car: <Car className={IconsClassName} aria-hidden="true" />,
    wheel: <Computer className={IconsClassName} aria-hidden="true" />,
    handshake: <Handshake className={IconsClassName} aria-hidden="true" />,
    discussion: <Discussion className={IconsClassName} aria-hidden="true" />,
  };

  return (
    <div className="shadow-[0_3px_20px_rgba(0,0,0,.16)] p-8 group text-black hover:bg-primary hover:text-white h-full">
      <h1 className="text-5xl 2xl:text-6xl">
        {index < 10 ? `0${index}` : index}
      </h1>
      <div className="flex flex-col items-center text-center pb-8">
        <div className="text-black group-hover:text-white py-8">
          {icons[commitment.field_pictogram]}
        </div>
        <h2 className="text-3xl 2xl:text-4xl whitespace-nowrap pb-6">
          {commitment.title}
        </h2>
        <Body value={commitment.body?.processed} />
      </div>
    </div>
  );
}
