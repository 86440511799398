import Body from "components/Body";
import React from "react";
import classNames from "lib/classNames";
import Phone from "components/Icons/Phone";
import Map from "components/Icons/Map";

export default function BasicBlock({ data, className, phone, footer }) {
  return (
    <div
      className={classNames(
        "bg-black bg-opacity-50 pt-4 pb-5 2xl:pt-6 2xl:pb-10 h-full relative z-40",
        className
      )}
    >
      {phone
        ? !footer && <Phone className="w-8 h-8 text-primary mb-1" />
        : !footer && <Map className="w-7 h-7 text-primary mb-1" />}
      <h2
        className={classNames(
          footer ? "font-sans font-semibold" : "font-teko",
          "mb-2 text-xl 2xl:text-3xl text-center sm:text-start text-white uppercase tracking-wider"
        )}
      >
        {data.info}
      </h2>
      <div className="text-white/75 text-sm text-center sm:text-start">
        <Body value={data.body?.value} />
      </div>
    </div>
  );
}
