import React from "react";

export default function Map({ className }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 732 1024"
      className={className}
    >
      <path
        fill="currentColor"
        d="M366.091 0c202.348 1.342 365.864 165.687 365.864 368.223 0 57.813-13.323 112.515-37.067 161.202l0.96-2.181c-53.832 110.989-288.112 439.103-298.008 453.010l-31.291 43.746-31.023-43.937c-9.704-13.716-238.177-337.627-293.461-443.879-26.463-49.36-42.009-107.988-42.009-170.246 0-202.123 163.853-365.976 365.976-365.976 0.021 0 0.041 0 0.062 0h-0.003zM366.893 891.73c66.746-94.751 220.373-315.697 260.184-397.726 17.983-36.513 28.502-79.483 28.502-124.909 0-0.036 0-0.071 0-0.107v0.006-0.42c0.008-0.781 0.012-1.705 0.012-2.629 0-159.922-129.642-289.564-289.564-289.564s-289.564 129.642-289.564 289.564c0 49.338 12.34 95.795 34.101 136.451l-0.759-1.553c41.224 79.239 191.413 296.785 257.089 390.887z"
      />
      <path
        fill="currentColor"
        d="M199.647 328.485c21.908-92.391 114.566-149.528 206.957-127.62s149.528 114.566 127.62 206.957c-21.908 92.391-114.566 149.528-206.957 127.62v0c-92.391-21.908-149.528-114.566-127.62-206.957v0zM459.539 390.112c12.171-51.328-19.572-102.804-70.9-114.976s-102.805 19.572-114.976 70.9c-12.171 51.328 19.572 102.805 70.9 114.976 0.118 0.028 0.235 0.056 0.353 0.083l-0.018-0.004c51.229 11.947 102.498-19.768 114.641-70.979 0 0 0 0 0-0.001v0z"
      />
    </svg>
  );
}
