import React from "react";
import Link from "next/link";
import Linkedin from "components/Icons/Linkedin";
import Facebook from "components/Icons/Facebook";
import Instagram from "components/Icons/Instagram";
import { ExternalLinkIcon } from "@heroicons/react/solid";
import classNames from "lib/classNames";

export default function SocialNetworksMenu({ menu }) {
  const IconsClassName = "w-[40px] hover:text-white";
  const icons = {
    facebook: <Facebook className={IconsClassName} aria-hidden="true" />,
    linkedin: <Linkedin className={IconsClassName} aria-hidden="true" />,
    instagram: <Instagram className={IconsClassName} aria-hidden="true" />,
  };

  return (
    <ul className="flex flex-row text-primary justify-center mt-2 space-x-4">
      {menu.map((item) => {
        let linkIcon;
        let defaultIcon = false;
        if (
          Object.prototype.hasOwnProperty.call(
            icons,
            item.options.attributes.class
          )
        ) {
          linkIcon = icons[item.options.attributes.class];
        } else {
          defaultIcon = true;
          linkIcon = (
            <ExternalLinkIcon
              className="h-6 w-6 fill-primary"
              aria-hidden="true"
            />
          );
        }
        return (
          <li key={item.id}>
            <Link href={item.url} passHref>
              <a
                target="_blank"
                className={
                  defaultIcon
                    ? "flex items-center bg-white self-center h-[30px]"
                    : ""
                }
              >
                {linkIcon}
                <span
                  className={classNames(
                    !defaultIcon ? "sr-only" : "text-primary-dark pl-1 pr-2"
                  )}
                >
                  {item.title}
                </span>
              </a>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
