import React from "react";
import Image from "next/image";
import Link from "next/link";
import classNames from "lib/classNames";

export default function Branding({ globalScrollY }) {
  return (
    <div className="bg-black lg:p-8 pl-2 pr-5 lg:border-x lg:border-white">
      <div
        className={classNames(
          globalScrollY !== 0 ? "xl:w-20 2xl:w-28" : "xl:w-36 2xl:w-44",
          "aspect-w-3 aspect-h-2 transition-all w-20"
        )}
      >
        <Link href="/" passHref>
          <a>
            <Image
              src="/images/motors-legend-logo.png"
              objectFit="contain"
              alt="Logo Motors Legend"
              layout="fill"
            />
          </a>
        </Link>
      </div>
    </div>
  );
}
