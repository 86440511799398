export default function useField(field) {
  const formId = field["#webform"];
  const id = field["#webform_id"];
  const name = field["#webform_key"];
  const type = field["#type"];
  const disabled = field["#disabled"] || false;
  const required = field["#required"] || false;
  const requiredMessage = field["#required_error"] || "Le champ est requis.";
  const wrapperClass = field["#wrapper_attributes"] || [];
  const defaultValue = field["#default_value"];
  const rows = field["#rows"] || false;
  const title = field["#title"];
  const placeholder = field["#placeholder"];
  const description = field["#description"] || false;
  const options = Object.prototype.hasOwnProperty.call(field, "#options")
    ? Object.keys(field["#options"]).map((key) => {
        return {
          key: field["#options"][key],
          value: key,
        };
      })
    : null;

  return {
    formId,
    id,
    name,
    disabled,
    required,
    requiredMessage,
    options,
    wrapperClass,
    defaultValue,
    rows,
    description,
    type,
    title,
    placeholder,
  };
}
