import React from "react";
import MobileMenu from "components/Menu/MobileMenu";
import { useRouter } from "next/router";
import classNames from "lib/classNames";

export default function MainMenu({ menu }) {
  const router = useRouter();

  return (
    <>
      <nav className="hidden lg:block container mx-auto lg:ml-72 xl:max-w-2xl 2xl:max-w-6xl">
        <ul className="flex lg:space-x-16 2xl:space-x-24" role="menu">
          {menu.map((item) => {
            return (
              <li key={item.id} role="menuitem">
                <a href={item.url}>
                  <span
                    className={classNames(
                      router.asPath === item.url ? "text-primary" : "",
                      "inline-block font-teko text-xl font-normal tracking-wider uppercase hover:text-primary"
                    )}
                  >
                    {item.title}
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className="lg:hidden">
        <MobileMenu menu={menu} />
      </div>
    </>
  );
}
