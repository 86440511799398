import React from "react";

export default function Wheel({ className }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      className={className}
    >
      <path
        fill="currentColor"
        d="M517.803 1039.165c-287.893-0.028-521.265-233.417-521.265-521.314 0-287.914 233.4-521.314 521.314-521.314 287.897 0 521.286 233.373 521.314 521.263v0.002c-0.222 287.851-233.511 521.14-521.341 521.362h-0.021zM517.803 29.062c-269.903 0.028-488.692 218.834-488.692 488.741 0 269.924 218.817 488.741 488.741 488.741s488.741-218.817 488.741-488.741v0c-0.333-269.807-218.979-488.436-488.761-488.741h-0.029z"
      />
      <path
        fill="currentColor"
        d="M517.851 925.111c-224.95 0-407.308-182.358-407.308-407.308s182.358-407.308 407.308-407.308c224.95 0 407.308 182.358 407.308 407.308v0c-0.25 224.849-182.458 407.058-407.284 407.308h-0.024zM517.851 143.116c-206.934 0-374.686 167.753-374.686 374.686s167.753 374.686 374.686 374.686c206.934 0 374.686-167.753 374.686-374.686v0c-0.25-206.833-167.853-374.437-374.662-374.686h-0.024z"
      />
      <path
        fill="currentColor"
        d="M517.851 599.284c-45.001 0-81.481-36.48-81.481-81.481s36.48-81.481 81.481-81.481c45.001 0 81.481 36.48 81.481 81.481v0c-0.028 44.99-36.492 81.453-81.479 81.481h-0.003zM517.851 468.943c-26.93 0-48.762 21.831-48.762 48.762s21.831 48.762 48.762 48.762c26.93 0 48.762-21.831 48.762-48.762v0c0-26.93-21.831-48.762-48.762-48.762v0z"
      />
      <path
        fill="currentColor"
        d="M550.424 859.916h-1.658c-8.291-0.83-14.711-7.77-14.711-16.21 0-0.576 0.030-1.146 0.088-1.707l-0.006 0.070 13.946-139.41c4.253-41.354 38.906-73.337 81.029-73.337 12.144 0 23.667 2.658 34.019 7.424l-0.506-0.209 127.707 57.637c5.7 2.62 9.587 8.28 9.587 14.848 0 8.998-7.294 16.292-16.292 16.292-2.43 0-4.735-0.532-6.806-1.485l0.101 0.042-127.707-57.637c-5.936-2.772-12.888-4.389-20.218-4.389-25.282 0-46.070 19.241-48.52 43.878l-0.016 0.203-13.946 139.41c-0.869 8.248-7.784 14.622-16.188 14.629h-0.001z"
      />
      <path
        fill="currentColor"
        d="M406.528 406.479c-12.183-0.021-23.733-2.714-34.1-7.524l0.503 0.209-127.707-57.734c-5.7-2.62-9.587-8.28-9.587-14.848 0-8.998 7.294-16.292 16.292-16.292 2.43 0 4.735 0.532 6.806 1.485l-0.101-0.042 127.707 57.637c5.936 2.772 12.888 4.389 20.218 4.389 25.282 0 46.070-19.241 48.52-43.878l0.016-0.203 13.946-139.41c0.858-8.269 7.789-14.662 16.213-14.662 9 0 16.295 7.296 16.295 16.295 0 0.575-0.030 1.144-0.088 1.704l0.006-0.070-13.946 139.41c-4.335 41.304-38.88 73.245-80.925 73.435h-0.019z"
      />
      <path
        fill="currentColor"
        d="M485.327 859.916c-8.405-0.007-15.32-6.38-16.183-14.558l-0.006-0.071-13.946-139.41c-2.466-24.84-23.255-44.081-48.537-44.081-7.33 0-14.282 1.617-20.518 4.514l0.301-0.125-127.707 57.637c-1.97 0.912-4.275 1.444-6.705 1.444-8.998 0-16.292-7.294-16.292-16.292 0-6.568 3.887-12.228 9.486-14.806l0.101-0.042 127.854-57.685c9.846-4.557 21.369-7.216 33.513-7.216 42.123 0 76.776 31.983 81 72.989l0.029 0.348 13.946 139.41c0.053 0.491 0.082 1.060 0.082 1.637 0 8.44-6.42 15.38-14.643 16.205l-0.068 0.006h-1.658z"
      />
      <path
        fill="currentColor"
        d="M816.518 668.818c-0.001 0-0.003 0-0.005 0-3.57 0-6.87-1.152-9.551-3.104l0.046 0.032-113.762-81.774c-20.635-14.972-33.9-39.009-33.9-66.146s13.265-51.174 33.665-65.983l0.235-0.163 113.518-81.53c2.709-2.085 6.15-3.342 9.884-3.342 8.995 0 16.286 7.292 16.286 16.286 0 5.586-2.812 10.515-7.098 13.449l-0.056 0.036-113.762 81.774c-12.436 8.952-20.438 23.388-20.438 39.692s8.002 30.74 20.293 39.593l0.144 0.099 113.762 81.774c4.121 2.993 6.769 7.795 6.769 13.216 0 8.995-7.292 16.286-16.286 16.286-0.083 0-0.165-0.001-0.247-0.002h0.012z"
      />
      <path
        fill="currentColor"
        d="M629.126 406.479c-42.065-0.191-76.61-32.131-80.915-73.081l-0.030-0.354-14.043-139.459c-0.052-0.49-0.082-1.058-0.082-1.634 0-9 7.296-16.295 16.295-16.295 8.424 0 15.356 6.393 16.207 14.592l0.006 0.070 13.946 139.41c2.466 24.84 23.255 44.081 48.537 44.081 7.33 0 14.282-1.617 20.518-4.514l-0.301 0.125 127.707-57.637c1.97-0.912 4.275-1.444 6.705-1.444 8.998 0 16.292 7.294 16.292 16.292 0 6.568-3.887 12.228-9.486 14.806l-0.101 0.042-127.707 57.637c-9.864 4.604-21.414 7.297-33.591 7.314h-0.006z"
      />
      <path
        fill="currentColor"
        d="M219.38 668.77c-0.070 0.001-0.152 0.002-0.235 0.002-8.995 0-16.286-7.292-16.286-16.286 0-5.421 2.648-10.223 6.722-13.184l0.047-0.032 113.762-81.774c12.436-8.952 20.438-23.388 20.438-39.692s-8.002-30.74-20.293-39.593l-0.144-0.099-113.762-81.774c-4.341-2.97-7.153-7.899-7.153-13.485 0-8.995 7.292-16.286 16.286-16.286 3.734 0 7.175 1.257 9.922 3.371l-0.038-0.028 113.762 81.774c20.635 14.972 33.9 39.009 33.9 66.146s-13.265 51.174-33.665 65.983l-0.235 0.163-113.762 81.774c-2.634 1.92-5.934 3.072-9.504 3.072-0.002 0-0.003 0-0.005 0v0z"
      />
      <path
        fill="currentColor"
        d="M766.732 350.647c0 0-0.001 0-0.001 0-5.361 0-10.117-2.59-13.085-6.588l-0.031-0.044c-47.615-64.037-119.512-107.716-201.812-117.384l-1.379-0.132c-8.196-0.927-14.507-7.821-14.507-16.189 0-8.995 7.292-16.286 16.286-16.286 0.627 0 1.246 0.035 1.854 0.104l-0.075-0.007c92.984 10.793 172.907 59.251 225.249 129.476l0.616 0.864c2.002 2.675 3.206 6.049 3.206 9.704 0 8.991-7.285 16.28-16.274 16.286h-0.001z"
      />
      <path
        fill="currentColor"
        d="M268.922 350.598c-8.99-0.007-16.275-7.296-16.275-16.286 0-3.655 1.204-7.029 3.237-9.746l-0.030 0.043c52.908-71.174 132.816-119.716 224.286-130.438l1.53-0.146c0.534-0.062 1.153-0.098 1.78-0.098 8.995 0 16.286 7.292 16.286 16.286 0 8.368-6.311 15.262-14.432 16.182l-0.075 0.007c-83.679 9.8-155.576 53.479-202.634 116.733l-0.557 0.783c-2.999 4.041-7.755 6.632-13.116 6.632 0 0-0.001 0-0.001 0v0z"
      />
      <path
        fill="currentColor"
        d="M801.353 658.042c-0.023 0-0.051 0-0.078 0-2.337 0-4.557-0.503-6.557-1.406l0.1 0.041c-5.792-2.581-9.756-8.288-9.756-14.92 0-2.364 0.504-4.611 1.41-6.639l-0.041 0.103c15.568-34.574 24.638-74.95 24.638-117.443s-9.070-82.869-25.379-119.295l0.741 1.852c-0.864-1.924-1.368-4.17-1.368-6.534 0-8.996 7.293-16.289 16.289-16.289 6.632 0 12.339 3.964 14.88 9.652l0.041 0.103c17.286 38.424 27.356 83.292 27.356 130.511s-10.070 92.087-28.18 132.572l0.824-2.061c-2.592 5.783-8.293 9.74-14.92 9.752h-0.002z"
      />
      <path
        fill="currentColor"
        d="M552.229 841.63c-8.984-0.014-16.262-7.3-16.262-16.286 0-8.368 6.311-15.262 14.432-16.182l0.075-0.007c83.679-9.8 155.576-53.479 202.634-116.733l0.557-0.783c3.009-4.008 7.751-6.573 13.093-6.573 9.016 0 16.326 7.309 16.326 16.326 0 3.675-1.214 7.066-3.263 9.794l0.030-0.042c-52.904 71.178-132.815 119.722-224.287 130.439l-1.529 0.146h-1.804z"
      />
      <path
        fill="currentColor"
        d="M483.426 841.63h-1.804c-93.001-10.863-172.912-59.406-225.197-129.713l-0.619-0.871c-2.019-2.686-3.233-6.078-3.233-9.752 0-9.016 7.309-16.326 16.326-16.326 5.342 0 10.084 2.565 13.062 6.531l0.030 0.042c47.615 64.037 119.512 107.716 201.812 117.384l1.379 0.132c8.196 0.927 14.507 7.821 14.507 16.189 0 8.986-7.278 16.273-16.261 16.286h-0.001z"
      />
      <path
        fill="currentColor"
        d="M234.301 658.042c-6.628-0.012-12.329-3.97-14.88-9.649l-0.041-0.103c-17.297-38.422-27.374-83.29-27.374-130.511s10.077-92.089 28.198-132.57l-0.824 2.059c2.582-5.791 8.289-9.755 14.921-9.755 8.996 0 16.289 7.293 16.289 16.289 0 2.364-0.504 4.61-1.409 6.637l0.041-0.103c-15.579 34.573-24.656 74.948-24.656 117.443s9.077 82.871 25.397 119.293l-0.741-1.85c0.865 1.924 1.369 4.171 1.369 6.535 0 6.632-3.964 12.338-9.652 14.879l-0.103 0.041c-1.906 0.863-4.133 1.365-6.477 1.365-0.020 0-0.040 0-0.061 0h0.003z"
      />
      <path
        fill="currentColor"
        d="M852.943 197.486c-8.995 0-16.286-7.292-16.286-16.286s7.292-16.286 16.286-16.286v0c3.17 0 5.705 0 6.632-5.9 1.481-7.594 8.081-13.246 16-13.246 8.995 0 16.286 7.292 16.286 16.286 0 0.647-0.038 1.285-0.111 1.912l0.007-0.076c-2.020 18.929-17.904 33.543-37.201 33.543-0.636 0-1.269-0.016-1.897-0.047l0.088 0.004z"
      />
      <path
        fill="currentColor"
        d="M166.863 893.611c-8.995 0-16.286-7.292-16.286-16.286v0c-0.011-0.339-0.017-0.738-0.017-1.138 0-21.113 17.116-38.229 38.229-38.229 0.4 0 0.799 0.006 1.197 0.018l-0.058-0.001c8.995 0 16.286 7.292 16.286 16.286s-7.292 16.286-16.286 16.286v0c-6.729 0.293-6.729 4.194-6.729 6.729 0 8.995-7.292 16.286-16.286 16.286v0z"
      />
      <path
        fill="currentColor"
        d="M997.376 664.625c-14.131-0.095-26.384-8.044-32.622-19.695l-0.098-0.2c-1.371-2.336-2.18-5.145-2.18-8.143 0-9.001 7.296-16.297 16.297-16.297 6.002 0 11.247 3.245 14.075 8.077l0.042 0.077c1.902 3.316 3.218 4.584 7.997 2.926 1.598-0.576 3.442-0.908 5.364-0.908 8.998 0 16.293 7.294 16.293 16.293 0 7.076-4.511 13.099-10.815 15.349l-0.114 0.035c-4.221 1.546-9.094 2.454-14.175 2.487h-0.014z"
      />
      <path
        fill="currentColor"
        d="M64.122 415.305c-0.011 0-0.024 0-0.036 0-5.997 0-11.236-3.241-14.063-8.066l-0.042-0.077c-3.608-5.705-6.973-3.755-9.216-2.438-2.336 1.371-5.145 2.18-8.143 2.18-9.001 0-16.297-7.296-16.297-16.297 0-6.002 3.245-11.247 8.077-14.075l0.077-0.042c5.668-3.502 12.539-5.577 19.895-5.577 14.523 0 27.156 8.087 33.643 20.004l0.1 0.201c1.354 2.324 2.154 5.116 2.154 8.095 0 8.981-7.269 16.263-16.244 16.286h-0.002z"
      />
      <path
        fill="currentColor"
        d="M654.531 1020.294c-0.011 0-0.024 0-0.037 0-3.451 0-6.65-1.076-9.281-2.912l0.053 0.035c-10.8-6.845-17.865-18.734-17.865-32.273 0-7.403 2.113-14.313 5.768-20.16l-0.093 0.16c2.87-4.909 8.114-8.154 14.117-8.154 9.001 0 16.297 7.296 16.297 16.297 0 2.998-0.81 5.807-2.222 8.22l0.042-0.077c-1.414 2.487-3.072 5.364 2.487 9.216 4.278 2.977 7.043 7.871 7.043 13.41 0 8.995-7.292 16.286-16.286 16.286-0.008 0-0.015 0-0.023 0h0.001z"
      />
      <path
        fill="currentColor"
        d="M395.654 78.653c-0.032 0-0.069 0-0.106 0-8.995 0-16.286-7.292-16.286-16.286 0-2.979 0.8-5.77 2.196-8.173l-0.042 0.078c3.267-6.193 0-8.143-2.341-9.411-4.909-2.87-8.154-8.114-8.154-14.117 0-9.001 7.296-16.297 16.297-16.297 2.998 0 5.807 0.81 8.22 2.222l-0.077-0.042c12.128 6.571 20.225 19.202 20.225 33.724 0 7.365-2.082 14.243-5.691 20.078l0.094-0.164c-2.868 4.902-8.108 8.143-14.105 8.143-0.013 0-0.026 0-0.038 0h0.002z"
      />
      <path
        fill="currentColor"
        d="M640.195 76.8c-8.986-0.012-16.265-7.299-16.265-16.286 0-5.988 3.231-11.221 8.046-14.051l0.076-0.042c2.487-1.463 5.364-3.072 2.438-9.265-1.421-2.369-2.262-5.226-2.262-8.281 0-8.995 7.292-16.286 16.286-16.286 6.924 0 12.838 4.32 15.195 10.412l0.038 0.111c2.838 5.2 4.506 11.39 4.506 17.97 0 14.413-8.006 26.956-19.814 33.423l-0.198 0.099c-2.335 1.376-5.144 2.19-8.142 2.194h-0.001z"
      />
      <path
        fill="currentColor"
        d="M394.338 1022.878c-0.011 0-0.024 0-0.036 0-5.997 0-11.236-3.241-14.063-8.066l-0.042-0.077c-3.514-5.671-5.596-12.549-5.596-19.914 0-14.522 8.097-27.153 20.023-33.624l0.202-0.1c2.336-1.371 5.145-2.18 8.143-2.18 9.001 0 16.297 7.296 16.297 16.297 0 6.002-3.245 11.247-8.077 14.075l-0.077 0.042c-5.705 3.608-3.755 6.973-2.487 9.216 1.354 2.324 2.154 5.116 2.154 8.095 0 8.981-7.269 16.263-16.244 16.286h-0.002z"
      />
      <path
        fill="currentColor"
        d="M989.038 411.892c-7.521-0.085-14.528-2.214-20.512-5.855l0.179 0.101c-5.187-2.793-8.653-8.185-8.653-14.387 0-8.995 7.292-16.286 16.286-16.286 3.208 0 6.199 0.927 8.72 2.528l-0.066-0.039c3.267 1.902 4.876 2.341 8.338-1.463 3.006-3.467 7.417-5.646 12.337-5.646 9.003 0 16.301 7.298 16.301 16.301 0 4.083-1.501 7.815-3.981 10.675l0.017-0.020c-6.913 8.505-17.309 13.949-28.981 14.141h-0.032z"
      />
      <path
        fill="currentColor"
        d="M34.523 668.331c-8.977-0.023-16.246-7.306-16.246-16.286 0-2.979 0.8-5.77 2.196-8.172l-0.042 0.078c6.571-12.128 19.202-20.225 33.724-20.225 7.365 0 14.243 2.082 20.078 5.691l-0.164-0.094c4.909 2.87 8.154 8.114 8.154 14.117 0 9.001-7.296 16.297-16.297 16.297-2.998 0-5.807-0.81-8.22-2.222l0.077 0.042c-5.949-3.072-7.899 0-9.216 2.438-2.868 4.902-8.108 8.143-14.105 8.143-0.013 0-0.026 0-0.038 0h0.002z"
      />
      <path
        fill="currentColor"
        d="M877.714 888.344c-0.387 0.034-0.837 0.053-1.292 0.053s-0.905-0.019-1.35-0.057l0.058 0.004c-18.864-2.114-33.395-17.975-33.395-37.229 0-0.626 0.015-1.249 0.046-1.868l-0.003 0.087c0-8.995 7.292-16.286 16.286-16.286s16.286 7.292 16.286 16.286v0c0 3.511 0.293 5.754 5.9 6.632 7.849 1.26 13.774 7.984 13.774 16.091 0 8.995-7.292 16.286-16.286 16.286-0.008 0-0.016 0-0.024 0h0.001z"
      />
      <path
        fill="currentColor"
        d="M184.808 202.606c-8.995 0-16.286-7.292-16.286-16.286v0c-0.293-6.973-4.194-6.973-6.778-6.973-8.995 0-16.286-7.292-16.286-16.286s7.292-16.286 16.286-16.286v0c0.339-0.011 0.738-0.017 1.138-0.017 21.113 0 38.229 17.116 38.229 38.229 0 0.4-0.006 0.799-0.018 1.197l0.001-0.058c0 8.995-7.292 16.286-16.286 16.286v0z"
      />
      <path
        fill="currentColor"
        d="M664.478 794.77c-0.016 0-0.034 0-0.052 0-6.347 0-11.846-3.631-14.533-8.928l-0.043-0.093-16.286-32.573c-1.089-2.131-1.727-4.648-1.727-7.314 0-9.033 7.322-16.355 16.355-16.355 6.367 0 11.884 3.638 14.586 8.948l0.043 0.093 16.286 32.573c1.079 2.117 1.71 4.618 1.71 7.266 0 8.995-7.292 16.286-16.286 16.286-0.018 0-0.037 0-0.055 0h0.003z"
      />
    </svg>
  );
}
