import React, { Fragment, useContext } from "react";
import { ModalContext } from "contexts/ModalContext";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

export default function Modal() {
  const { modalIsOpen, setModalIsOpen, modalContent } =
    useContext(ModalContext);

  return (
    <Transition show={modalIsOpen} as={Fragment}>
      <Dialog
        as="div"
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        className="fixed inset-0 z-50 overflow-auto"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="container mx-auto px-10">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <div className="relative">
              <button
                type="button"
                className="fixed right-10 top-10 bg-gray-200 hover:bg-white rounded-full text-black z-50 p-1"
                onClick={() => setModalIsOpen(false)}
              >
                <span className="sr-only">Fermer</span>
                <XIcon className="h-4 w-4" aria-hidden="true" />
              </button>
              {modalContent}
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
