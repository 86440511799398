import React from "react";

export default function Down({ className }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1904 1024"
      className={className}
    >
      <path
        fill="currentColor"
        d="M902.044 951.837c0-0.041 0-0.089 0-0.137 0-19.606 7.819-37.386 20.509-50.392l-0.014 0.015 857.302-879.673c13.12-13.446 31.42-21.785 51.668-21.785 19.674 0 37.51 7.873 50.528 20.641l-0.012-0.011c13.446 13.12 21.785 31.42 21.785 51.668 0 19.674-7.873 37.51-20.641 50.528l0.011-0.012-857.302 879.673c-13.12 13.447-31.421 21.786-51.669 21.786-39.855 0-72.163-32.309-72.163-72.163 0-0.048 0-0.096 0-0.145v0.007z"
      />
      <path
        fill="currentColor"
        d="M0 72.163c0-0.040 0-0.087 0-0.134 0-20.181 8.284-38.427 21.638-51.524l0.012-0.011c13.006-12.757 30.842-20.63 50.516-20.63 20.248 0 38.548 8.339 51.653 21.77l0.014 0.015 857.302 879.817c12.713 12.998 20.558 30.804 20.558 50.442 0 20.212-8.31 38.484-21.699 51.584l-0.013 0.013c-12.998 12.714-30.805 20.56-50.444 20.56-20.284 0-38.613-8.369-51.724-21.842l-0.016-0.016-857.591-879.529c-12.503-12.958-20.207-30.62-20.207-50.081 0-0.152 0-0.305 0.001-0.457v0.023z"
      />
    </svg>
  );
}
